import Client from "./client";

export const login = async (username, password) => {
  try {

    const response = await Client.User.post("/login", { username, password });
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
    // return error.response?.data?.message || "Internal server error";
  }
};
