import { useState } from "react";
import styled from "styled-components";
import UpdatePasswordModal from "../../components/PasswordModal";
import SubTitle from "../../components/SubTitle";

const Container = styled.div`
  width: 100%;
  margin-bottom: 153px;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const Button = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 98px;
  width: 122px;
  height: 56px;
  border-radius: 15px;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  cursor: pointer;
  background-color: #00a7db;
  &:hover {
    background-color: #0087b3;
  }
`;

export const Setting = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <SubTitle title="설정" subTitle="비밀번호 변경" />
      <Button onClick={() => setIsOpen(true)}>변경</Button>
      {isOpen && <UpdatePasswordModal onClose={() => setIsOpen(false)} />}
    </Container>
  );
};

