import { useEffect, useRef, useState } from "react";

export default function PhoneForm({
  phoneNumberType,
  phoneNumberData,
  onPhoneNumberChange,
}) {
  const [firstPart, setFirstPart] = useState("");
  const [secondPart, setSecondPart] = useState("");
  const [thirdPart, setThirdPart] = useState("");
  const firstNumber = useRef(null);
  const secondNumber = useRef(null);
  const thirdNumber = useRef(null);

  // console.log(firstNumber, secondNumber, thirdNumber);

  const handleChangeFirstPart = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setFirstPart(value);
      updatePhoneNumber(value, secondPart, thirdPart);
    }
  };

  const handleChangeSecondPart = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setSecondPart(value);
      updatePhoneNumber(firstPart, value, thirdPart);

      // 두 번째 칸이 비어있고 첫 번째 칸이 3글자 이하일 때 첫 번째 칸으로 포커스 이동
      if (value.length === 0 && firstPart.length <= 3) {
        firstNumber.current.focus();
      }
    }
  };

  const handleChangeThirdPart = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setThirdPart(value);
      updatePhoneNumber(firstPart, secondPart, value);

      // 세 번째 칸이 비어있고 두 번째 칸이 4글자 이하일 때 두 번째 칸으로 포커스 이동
      if (value.length === 0 && secondPart.length <= 4) {
        secondNumber.current.focus();
      }
    }
  };

  const updatePhoneNumber = (first, second, third) => {
    // 두 번째와 세 번째 부분이 비어있을 경우 빈 문자열로 설정
    const combinedNumber = `${first}-${second || ''}-${third || ''}`;
    onPhoneNumberChange(combinedNumber, phoneNumberType);
  };

  useEffect(() => {
    if (phoneNumberData !== null) {
      const phoneNumSplit = phoneNumberData?.split("-");
      setFirstPart(phoneNumSplit[0] || "");
      setSecondPart(phoneNumSplit[1] || "");
      setThirdPart(phoneNumSplit[2] || "");
    } else {
      setFirstPart("");
      setSecondPart("");
      setThirdPart("");
    }
  }, [phoneNumberData]);

  useEffect(() => {
    if (firstPart?.length >= 3 && secondNumber.current) {
      secondNumber.current.focus();
    }
  }, [firstPart]);

  useEffect(() => {
    if (secondPart?.length >= 4 && thirdNumber.current) {
      thirdNumber.current.focus();
    }
  }, [secondPart]);

  const handleInputClick = (event) => {
    event.currentTarget.select(); // input 클릭 시 모든 텍스트를 선택
  };
  return (
    <>
      <div className="phone_input">
        <input
          className="phone_input_item"
          type="text"
          ref={firstNumber}
          maxLength={3}
          value={firstPart}
          onClick={handleInputClick}
          onChange={handleChangeFirstPart}
          placeholder="000"
        />
        <span>-</span>
        <input
          className="phone_input_item"
          type="text"
          ref={secondNumber}
          maxLength={4}
          value={secondPart}
          onClick={handleInputClick}
          onChange={handleChangeSecondPart}
          placeholder="0000"
        />
        <span>-</span>
        <input
          className="phone_input_item"
          type="text"
          ref={thirdNumber}
          maxLength={4}
          value={thirdPart}
          onClick={handleInputClick}
          onChange={handleChangeThirdPart}
          placeholder="0000"
        />
      </div>
    </>
  );
}