import axios from "axios";

export const BASE_URL = `${process.env.REACT_APP_API_URL || ""}/api`;

const admin = axios.create({
  baseURL: BASE_URL,
});

admin.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }
  return config;
});

admin.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      window.location.href = "/admin/login";
      window.alert("로그인이 만료되었습니다. 다시 로그인 해주세요.");
    }
    return Promise.reject(error);
  }
);

const user = axios.create({
  baseURL: BASE_URL,
});

const Client = {
  Admin: admin,
  User: user,
};

export default Client;
