import { ReactComponent as Circle } from "../assets/icons/circle.svg";

import styled from "styled-components";
import "animate.css";
import ServiceImage from "../assets/images/finance_bg.jpg";
import SubTitle from "./SubTitle";

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  padding-bottom: 140px;
`;

export const ServiceContentContainer = styled.div`
  display: flex;
`;

export const ServiceContent = styled.div`
  line-height: 1.4;
  margin-bottom: 20px;
  width: 50%;
  padding-right:55px;
  font-size: 1.8rem;

  p {
    line-height: 1.8;
    animation: fadeInDown 1.5s forwards;

    @keyframes fadeInDown {
      from {
        opacity: 0;
        transform: translateY(-30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    } 
  }
`;

export const ServiceListContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
  animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  }
`;

export const ServiceListTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
> img{
  width: 10px;
  height: 10px;
}

> h5 {
  font-size: 2.1rem;
}
`;

export const ServiceList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
`;

export const ServiceListItem = styled.li`
>span{
  margin-right: 10px;
}
`;

export const ServiceImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex: 1;
`;

export const ServiceImageItem = styled.img`
 width: 100%;
 height: 100%;
 animation: fadeInRight 1.5s forwards;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
`;

export function Finance() {
  return (
    <ServiceContainer>
      <SubTitle title="재무자문" />
      <ServiceContentContainer>
        <ServiceContent>
          <p>회계법인 새시대는 장부기장 대행, M&A 자문, 가치평가, 기업지배구조 개편,
            구조조정 등에서 고객의 기업가치를 극대화 할 수 있는 최고의
            자문 서비스를 제공하고 합니다.
          </p>
          <ServiceListContainer>
            <ServiceListTitle>
              <Circle />
              <h5> 서비스 항목</h5>
            </ServiceListTitle>
            <ServiceList>
              <ServiceListItem><span>-</span>장부기장 대행</ServiceListItem>
              <ServiceListItem><span>-</span>M&A(분할 포함)</ServiceListItem>
              <ServiceListItem><span>-</span>가치평가 업무</ServiceListItem>
            </ServiceList>
          </ServiceListContainer>
        </ServiceContent>
        <ServiceImageWrapper>
          <ServiceImageItem src={ServiceImage} alt="회계감사 이미지" />
        </ServiceImageWrapper>
      </ServiceContentContainer>
    </ServiceContainer >
  );
}


