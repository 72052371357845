import styled from "styled-components";

import NewVisual from "../components/NewVisual";
import LocationSection from "../components/LocationSection";
import ServicesSection from "../components/ServicesSection";
import { useRef, useState } from "react";
import FooterComponent from "../components/Footer";

const Container = styled.div`
width: 100%;
height: 100%;
max-height: 100000px;
overflow: hidden;
`;

export default function NewHome() {

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const isScrolling = useRef(false);

  const handleWheel = (e) => {
    if (isScrolling.current) return;
    isScrolling.current = true;

    // 스크롤 이벤트가 발생할 때마다 타이머를 초기화
    clearTimeout(isScrolling.timeout);
    isScrolling.timeout = setTimeout(() => {
      isScrolling.current = false;
    }, 400);

    // 스크롤 방향에 관계없이 항상 다음 또는 이전 섹션으로 이동
    if (e.deltaY > 0) {
      scrollToNextSection();
    } else {
      scrollToPrevSection();
    }
  };

  const scrollToNextSection = () => {
    scrollToSection(currentSectionIndex + 1);
  };

  const scrollToPrevSection = () => {
    scrollToSection(currentSectionIndex - 1);
  };

  const scrollToSection = (index) => {
    if (index >= 0 && index < sectionRefs.length) {
      setCurrentSectionIndex(index);

      // 애니메이션을 해당 섹션이 활성화될 때 실행
      const target = sectionRefs[index].current;
      if (target) {
        target.classList.add('animate'); // 애니메이션 클래스 추가
        setTimeout(() => {
          target.classList.remove('animate'); // 애니메이션 클래스 제거
        }, 600); // 애니메이션 지속 시간과 일치시킴
      }

      // 스크롤 속도를 조절하기 위해 수동으로 스크롤 구현
      const start = window.scrollY;
      const end = target.getBoundingClientRect().top + window.scrollY;
      const distance = end - start;
      const duration = 600; // 스크롤 애니메이션 지속 시간 (밀리초)
      let startTime = null;

      const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const progress = Math.min(timeElapsed / duration, 1);
        window.scrollTo(0, start + distance * progress);

        if (progress < 1) {
          requestAnimationFrame(animation);
        }
      };

      requestAnimationFrame(animation);
    }
  };


  return (
    <>
      <Container onWheel={handleWheel}>
        <NewVisual ref={sectionRefs[0]} animate={currentSectionIndex === 0} />
        <ServicesSection ref={sectionRefs[1]} animate={currentSectionIndex === 1} />
        <LocationSection ref={sectionRefs[2]} animate={currentSectionIndex === 2} />
        <FooterComponent ref={sectionRefs[3]} />
      </Container>
    </>
  )
}
