import SubVisual from "../components/SubVisual";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import PhoneForm from "../components/PhoneNumberForm";
import { getMembers } from "../api/members";
import { createConsulting, sendConsultationEmail } from "../api/consultings";
import ConsultCompModal from "../components/ConsultCompModal";
import BackgroundImage from "../assets/images/visual_consult.jpg";
import styled, { keyframes } from "styled-components";
import ArrowIcon from "../assets/icons/arrow.svg";
import CheckIcon from "../assets/icons/check.svg";
import SubTitle from "../components/SubTitle";

const MainContent = styled.section`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1000px;

  @media (max-width: 1840px) {
    padding: 0 20px;
  }
`;

const ConsultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 140px;
  font-size: 1.7rem;
  `;

const ConsultFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px 0;
  animation: fadeInDown 1.5s forwards;

    @keyframes fadeInDown {
      from {
        opacity: 0;
        transform: translateY(-30px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    } 
`;

const ConsultItemContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
`;

const ConsultHalfItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 50%;

  >input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--border-c);
    padding: 0 15px;
    line-height: 1.4;
    font-size: 1.8rem;
  }

  > label {
    position: relative;
    width: fit-content;
      > span {
      color: #ff6666;
      position: absolute;
      left: calc(100% + 5px);
      top: -15%;
    }
  }
`;

const ConsultFullItemWrapper = styled(ConsultHalfItemWrapper)`
  width: 100%;
`;

const ConsultPhoneItemWrapper = styled(ConsultHalfItemWrapper)`
  font-size: 1.8rem;
  font-weight: 400;

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    > input {
      width: 100%;
      height:50px;
      text-align: center;
      border: 1px solid var(--border-c);
      border-radius: 10px;
      font-size: 1.8rem;
    }
  }
`;

const ConsultItemSelect = styled.select`
  width: 100%;
  height: 50px;

  border-radius: 10px;
  border: 1px solid var(--border-c);
  padding: 0 15px;
  background: url(${ArrowIcon}) no-repeat right 5px center;
  background-size: 30px;
  font-size: 1.8rem;
  line-height: 1.4;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

const ConsultTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid var(--border-c);
  padding: 15px;
  font-size: 1.8rem;

  line-height: 1.4;
`;



const ConsultAgreeInfoList = styled.ul`
  border: 1px solid var(--border-c);
  padding: 15px;
  border-radius: 10px;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ConsultAgreeItem = styled.li`
  line-height: 1.4;
  >span {
    font-weight: 700;
  }
`;

const CheckboxContainer = styled.div`
  width: 100%;
  height: 20px;
  color: #333333;
  font-weight: 700;
  line-height: 1.4;
  
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-flex;
    align-items: center;;
    gap: 14px;
    cursor: pointer;
  }

  input[type="checkbox"] + label::before {
    display: block;
    content: "";
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 6px;
    color: #fff;
    border: 1px solid var(--border-c);
  }

  input[type="checkbox"]:checked + label::before {
    background-image: url(${CheckIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2aa4d5;
    border: 1px solid var(--primary-c);
  }
`;

const ConsultButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 10px;
  font-size: 1.8rem;
  background-color: var(--primary-c);
  color: #fff;
  transition: all 0.2s ease;
  margin-top: 10px;

  &:hover {
    background-color: var(--primary-c-hover);
  }

  &:disabled {
    background-color: #ddd;
  }
`;

// 로딩 애니메이션 정의
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// 스타일드 컴포넌트로 로딩 스피너 생성
const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3; /* 연한 회색 */
  border-top: 4px solid #3498db; /* 파란색 */
  border-radius: 50%;
  width: 30px; /* 크기 조정: 버튼보다 작게 */
  height: 30px; /* 크기 조정: 버튼보다 작게 */
  animation: ${spin} 0.6s linear infinite; /* 애니메이션 속도 조정 */
  margin: 0 auto; /* 중앙 정렬 */
`;

export function Consulting() {
  const [isChecked, setIsChecked] = useState(false);
  const [members, setMembers] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [author, setAuthor] = useState("");
  const [phoneNumberData, setPhoneNumberData] = useState("");
  const [email, setEmail] = useState("");
  const [memberId, setMemberId] = useState(null);
  const [replyType, setReplyType] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const selectRef = useRef(null);
  const fetchMembers = async () => {
    const data = await getMembers();
    setMembers(data.members);
  };

  useEffect(() => {
    if (selectRef.current && memberId) {
      selectRef.current.focus();
    }
  }, [selectRef, memberId]);

  useEffect(() => {
    fetchMembers();
    const urlMemberId = searchParams.get("memberId");
    if (urlMemberId) {
      setMemberId(urlMemberId);
    }
  }, [searchParams]);

  const replyOptions = [
    { id: "call", name: "전화" },
    { id: "message", name: "문자" },
    { id: "email", name: "이메일" },
  ];


  const handleSubmit = async (e) => {
    e.preventDefault();

    // 필수입력항목 체크
    if (!companyName.trim() || !author.trim() || !phoneNumberData || !replyType || !title.trim() || !content.trim()) {
      alert("필수입력항목을 확인해주세요.");
      return;
    }

    // 이메일 유효성 검사
    if (email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      alert("올바른 이메일 형식이 아닙니다.");
      return;
    }

    try {
      setIsLoading(true);
      const data = {
        company: companyName,
        author,
        phone: phoneNumberData,
        email,
        memberId,
        howToReply: replyType,
        title,
        content,
      }
      await createConsulting(data); // 상담 생성

      try {
        await sendConsultationEmail(data); // 이메일 전송
      } catch (emailError) {
        console.error(emailError);
        alert("이메일 전송에 실패했습니다. 다시 시도해 주세요."); // 이메일 전송 오류 알림
      }

      setIsModalOpen(true);
    } catch (consultingError) {
      console.error(consultingError);
      alert("상담 신청에 실패했습니다. 다시 시도해 주세요."); // 상담 신청 오류 알림
    } finally {
      setIsLoading(false);
    }
  };

  const clearForm = () => {
    setCompanyName("");
    setAuthor("");
    setPhoneNumberData("");
    setEmail("");
    setMemberId(null);
    setReplyType("");
    setTitle("");
    setContent("");
    setIsChecked(false); // 체크박스 초기화
  }

  return (
    <>
      <SubVisual title="상담문의" menuList={["온라인 상담 신청"]} activeMenu={"온라인 상담 신청"} backgroundImage={BackgroundImage} />
      <MainContent>
        <ConsultContainer>
          <SubTitle title="온라인 상담 신청" />
          <ConsultFormContainer onSubmit={handleSubmit}>
            <ConsultItemContainer>
              <ConsultHalfItemWrapper>
                <label>회사명<span>*</span></label>
                <input type="text" value={companyName} onChange={({ target }) => setCompanyName(target.value)} placeholder="회사명을 입력해주세요." required />
              </ConsultHalfItemWrapper>
              <ConsultHalfItemWrapper >
                <label>이름<span>*</span></label>
                <input type="text" value={author} onChange={({ target }) => setAuthor(target.value)} placeholder="이름을 입력해주세요." required />
              </ConsultHalfItemWrapper>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultPhoneItemWrapper>
                <label>연락처<span>*</span></label>
                <PhoneForm phoneNumberType="phone" phoneNumberData={phoneNumberData} onPhoneNumberChange={setPhoneNumberData} />
              </ConsultPhoneItemWrapper>
              <ConsultHalfItemWrapper>
                <label>이메일</label>
                <input
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  placeholder="이메일을 입력해주세요."
                  onChange={({ target }) => setEmail(target.value)}
                  required={replyType === "email"}
                  value={email}
                />
              </ConsultHalfItemWrapper>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultHalfItemWrapper>
                <label >당담자 선택</label>
                <ConsultItemSelect
                  ref={selectRef}
                  value={memberId || ""}
                  onChange={({ target }) => setMemberId(target.value)}
                  className={memberId ? "member-selected" : ""}
                >
                  <option value="">담당자를 선택해주세요.</option>
                  {members.map((member) => (
                    <option key={member.id} value={member.id}>{member.name} {member.role}</option>
                  ))}
                </ConsultItemSelect>
              </ConsultHalfItemWrapper>
              <ConsultHalfItemWrapper>
                <label>답변 방법<span>*</span></label>
                <ConsultItemSelect onChange={({ target }) => setReplyType(target.value)} value={replyType}>
                  <option value="">답변 방법을 선택해주세요.</option>
                  {replyOptions.map((option) => (
                    <option key={option.id} value={option.id}>{option.name}</option>
                  ))}
                </ConsultItemSelect>
              </ConsultHalfItemWrapper>
            </ConsultItemContainer>

            <ConsultFullItemWrapper>
              <label>서비스명<span>*</span></label>
              <input type="text" value={title} onChange={({ target }) => setTitle(target.value)} placeholder="문의하실 서비스명을 입력해주세요.(ex. 회계, 세금, 경영자문 등)" required />
            </ConsultFullItemWrapper>
            <ConsultFullItemWrapper >
              <label>문의 내용<span>*</span></label>
              <ConsultTextarea value={content} onChange={({ target }) => setContent(target.value)} placeholder="문의 상세내용을 입력해주세요." required />
            </ConsultFullItemWrapper>
            <ConsultFullItemWrapper>
              <label>개인정보 수집 및 이용동의<span>*</span></label>
              <ConsultAgreeInfoList>
                <ConsultAgreeItem>
                  1. <span >수집하는 개인정보의 항목:</span> 회사명, 이름, 휴대폰 번호, 이메일, 신청 담당자, 답변 방법, 서비스명, 문의 내용
                </ConsultAgreeItem>
                <ConsultAgreeItem>
                  2. <span >개인정보의 수집 및 이용 목적:</span> 상담 및 문의에 대한 답변 제공, 서비스 개선 및 고객 관리
                </ConsultAgreeItem>
                <ConsultAgreeItem>
                  3. <span>개인정보의 보유 및 이용 기간: </span> 수집된 개인정보는 상담 완료 후 1년간 보관되며, 이후에는 안전하게 파기됩니다.
                </ConsultAgreeItem>
                <ConsultAgreeItem>
                  4. <span>개인정보의 제3자 제공:</span> 수집된  개인정보는 법적 요구가 있는 우를 제외하고는 제3자에게 제공되지 않습니다.
                </ConsultAgreeItem>
                <ConsultAgreeItem>
                  5. <span>개인정보 보호: </span> 귀하의 개인정보는 안전하게 보호되며, 관련 법령에 따라 관리됩니다.
                </ConsultAgreeItem>
              </ConsultAgreeInfoList>
            </ConsultFullItemWrapper>
            <CheckboxContainer>
              <input
                id="agree-checkbox"
                type="checkbox"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="agree-checkbox"> 개인정보 수집 및 이용에 동의합니다.</label>
            </CheckboxContainer>
            <ConsultFullItemWrapper>
              <ConsultButton disabled={!isChecked || isLoading} type="submit">
                {isLoading ? <LoadingSpinner /> : "상담 신청하기"}
              </ConsultButton>
              {isModalOpen && <ConsultCompModal onClose={() => {
                setIsModalOpen(false);
                window.scrollTo(0, 0);
                clearForm();
              }} />}
            </ConsultFullItemWrapper>
          </ConsultFormContainer>
        </ConsultContainer>
      </MainContent>
    </>
  );
}
