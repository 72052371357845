import Client from "./client";

export const getConsultings = async (page, size) => {
  const response = await Client.User.get("/consult", { params: { page, size } });
  return response.data;
};

export const getConsultingById = async (id) => {
  const response = await Client.User.get(`/consult/${id}`);
  return response.data;
};

export const createConsulting = async (consulting) => {
  const response = await Client.User.post("/consult", consulting);
  return response.data;
};

export const deleteConsulting = async (id) => {
  const response = await Client.Admin.delete(`/admin/consult/${id}`);
  return response.data;
};

export const updateConsulting = async (id, consulting) => {
  const response = await Client.Admin.put(`/admin/consult/${id}`, consulting);
  return response.data;
};

export const updateConsultationDone = async (id, done) => {
  const response = await Client.Admin.patch(`/admin/consult/${id}/done`, {
    done: done === "true",
  });
  return response.data;
};

export const updateConsultationMemberId = async (id, memberId) => {
  const response = await Client.Admin.patch(`/admin/consult/${id}/memberId`, {
    memberId: Number(memberId),
  });
  return response.data;
};

export const sendConsultationEmail = async (consulting) => {
  const response = await Client.User.post("/consult/email", consulting);

  return response.data;
};
