import styled from "styled-components";

const SubTitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  margin-top: 144px;
  margin-bottom: 34px;
  color: #2aa4d5;
  font-weight: 700;
  animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h3 {  
    font-size: 4rem;

    @media (max-width: 1400px) {
      font-size: calc( 5rem  * 0.78);
    }
  }

  span {
    font-size:3rem;
  }

  ::before {
    position: absolute;
    left: 0px;
    bottom: calc(100% + 15px);
    content: "";
    display: block;
    width: 50px;
    height: 5px;
    background-color: #186fb2;
  }
`;


export default function SubTitle({ title, subTitle }) {
  return (
    <>
      <SubTitleContainer>
        <h3>{title}</h3>
        {subTitle && <span>&gt; {subTitle}</span>}
      </SubTitleContainer>
    </>
  );
};

