import { Link, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { isLoginState, tokenState, userIdState } from "../store/atom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import { getUsers } from "../api/users";
import styled from "styled-components";
import LogoWhite from "../assets/images/logo_transparent.png";
import UserIcon from "../assets/icons/admin_user.png";
import UnlockIcon from "../assets/icons/unlock.png";

const Container = styled.main`
  width: 1600px;
  display: flex;
`;

const Content = styled.section`
  flex: 1;
  padding: 0 20px;
`;

const Sidebar = styled.aside`
  flex-shrink: 0;
  width: 220px;
  min-height: 100vh;
  padding: 34px 0;
  background-color: #00a7db;
`;

const Logo = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 44px;
  width: 150px;
  height: 28px;
  cursor: pointer;
`;
const UserIconImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 29px;
  width: 70px;
  height: 70px;
`;
const Greeting = styled.h1`
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  margin-bottom: 33px;
`;

const LogoutButton = styled.button`
  position: relative;
  display: block;
  margin: 0 auto;
  cursor: pointer;
  width: 120px;
  height: 36px;
  font-size: 1.6rem;
  color: #4e4e4e;
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  padding-left: 48px;

  img {
    position: absolute;
    left: 21px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 20px;
  }
`;

const MenuList = styled.ul`
  margin-top: 67px;
  font-size: 2rem;
  color: #fff;
  line-height: 56px;
`;

const MenuItem = styled.li`
  background-color: ${({ $isActive }) => ($isActive ? "#151531" : "#00a7db")};
  
  a {
    width: 100%;
    height: 100%;
    display: block;
    padding-left: 78px;

  }
  
`;

export default function AdminLayout() {
  const pathname = useLocation().pathname;
  const isLogin = useRecoilValue(isLoginState);
  const userId = useRecoilValue(userIdState);
  const [username, setUsername] = useState("");
  const isActive = useCallback(
    (path) => pathname.startsWith(path),
    [pathname]
  );

  const resetToken = useResetRecoilState(tokenState);
  const resetUserId = useResetRecoilState(userIdState);

  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      getUsers().then((data) => {
        const { users } = data;
        const curUser = users.find((user) => user.id === userId);
        setUsername(curUser.username);
      });
    }
  }, [isLogin, userId]);

  if (!isLogin) {
    resetToken();
    resetUserId();
    return <Navigate to="/admin/login" replace />;
  }

  const handleLogout = () => {
    resetToken();
    resetUserId();
    navigate("/admin/login");
  };

  return (
    <Container>
      <Sidebar>
        <Logo src={LogoWhite} alt="로고" onClick={() => navigate("/")} />
        <UserIconImg src={UserIcon} alt="유저 아이콘" />
        <Greeting>{username}님 환영합니다.</Greeting>
        <LogoutButton onClick={handleLogout}>
          <img src={UnlockIcon} alt="잠금 아이콘" />
          로그아웃
        </LogoutButton>

        <MenuList>
          <MenuItem $isActive={isActive("/admin/members")}>
            <Link to="/admin/members">구성원</Link>
          </MenuItem>
          <MenuItem $isActive={isActive("/admin/consulting")}>
            <Link to="/admin/consulting">상담문의</Link>
          </MenuItem>
          <MenuItem $isActive={isActive("/admin/setting")}>
            <Link to="/admin/setting">설정</Link>
          </MenuItem>
        </MenuList>
      </Sidebar>
      <Content>
        <Outlet />
      </Content>
    </Container >
  );
}
