import { useMemo, useState } from "react";
import styled from "styled-components";
import MobileSubTitle from "./MobileSubTitle";
import MobileKakaoMap from "./MobileKakaoMap";

const DirectionContainer = styled.div`
  padding: 60px 20px;
  font-size: 14px;
`;

const DirectionContent = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DirectionList = styled.ul`
  border: 1px solid #d4d4d4;
  display: flex;
  height: 46px;

  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

const DirectionListItem = styled.li`
  border-right: 1px solid #d4d4d4;
  flex: 1;

  &:last-child {
    border-right: none;
  }
`;

const DirectionListItemSpan = styled.span`
 height: 100%;
 display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
  color: #a4a4a4;

  &:hover{
    color: #fff;
    background-color: var(--primary-c-hover);
  transition: all 0.3s ease;
  } 

  &.active {
    color: #fff;
    background-color: var(--primary-c-hover);
  }
`;

const MapWrapper = styled.div`
  margin-bottom: 20px;
`;

const LocationInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  animation: fadeInRight 1.5s forwards;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const LocationTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  color: var(--primary-c-hover);
  border-bottom: 2px solid var(--primary-c-hover);
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const InfoItemIcon = styled.img`
  width: 70px;
  height: 70px;
`;

const AddressContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  line-height: 1.2;

  >h4{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const ContactContent = styled.div`
  width: 100%;
  display: flex;                                                                     
  flex-direction: column;
  gap: 5px;

  >h4{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }  
`;

const ContactList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 5px;
    line-height: 1.2;
`;

export default function MobileDirections() {

  const InfoData = [
    {
      name: "본점",
      address: "서울특별시 영등포구 양산로 107, 인곡빌딩 4층,7층,9층",
      tel: "02-6679-5500, 02-6679-5505",
      email: "ssd402@ssdac.kr, saesidae01@gmail.com"
    },
    {
      name: "구로분실",
      address: "서울특별시 구로구 경인로53길15, 407호(구로동, 업무A동)",
      tel: "0507-1460-5503",
      email: ""
    },
    {
      name: "강남분실",
      address: "서울특별시 강남구 테헤란로38길29, 6층(역삼동, 이원빌딩)",
      tel: "",
      email: ""
    },
    {
      name: "광주지점(분사무소)",
      address: "광주광역시 서구 상무대로759, 4층(치평동, 지산빌딩)",
      tel: "062-373-5573",
      email: ""
    }
  ]

  const locationList = useMemo(() => ["본점", "구로분실", "강남분실", "광주지점"], []);

  const [activeLocation, setActiveLocation] = useState("본점");
  const activeLocationIndex = useMemo(() => locationList.indexOf(activeLocation), [activeLocation, locationList]);

  const handleLocationClick = (location) => {
    setActiveLocation(location);
  }

  return (
    <DirectionContainer>
      <MobileSubTitle title="오시는 길" />
      <DirectionContent>
        <DirectionList>
          {locationList.map((location, index) => (
            <DirectionListItem key={index}>
              <DirectionListItemSpan className={activeLocation === location ? "active" : ""} onClick={() => handleLocationClick(location)}>{location}</DirectionListItemSpan>
            </DirectionListItem>
          ))}
        </DirectionList>
        <MapWrapper>
          <MobileKakaoMap activeLocation={activeLocation} height={"460px"} />
        </MapWrapper>
        <LocationInfoContainer>

          <LocationTitle>{activeLocation}</LocationTitle>
          <InfoContainer>
            <InfoWrapper>
              <InfoItemIcon src={require("../../assets/images/map.jpg")} alt="위치" />
              <AddressContent>
                <h4>주소</h4>
                <p>{InfoData[activeLocationIndex].address}</p>
              </AddressContent>
            </InfoWrapper>
            {InfoData[activeLocationIndex].tel &&
              <InfoWrapper>
                <InfoItemIcon src={require("../../assets/images/call.jpg")} alt="연락처" />
                <ContactContent>
                  <h4>연락처</h4>
                  <ContactList>
                    <li><span>- TEL</span>:  {InfoData[activeLocationIndex].tel}</li>
                    {InfoData[activeLocationIndex].fax && <li><span>- FAX</span>: {InfoData[activeLocationIndex].fax}</li>}
                    {InfoData[activeLocationIndex].email && <li><span>- EMAIL</span>: {InfoData[activeLocationIndex].email}</li>}
                  </ContactList>
                </ContactContent>
              </InfoWrapper>
            }
          </InfoContainer>
        </LocationInfoContainer>
      </DirectionContent>
    </DirectionContainer>
  );
}