import styled from "styled-components";
import { Outlet } from "react-router-dom";
import MobileSubTitle from "./MobileSubTitle";

const SubTitleContainer = styled.div`
  padding: 60px 20px 0;
`;


export default function MobileAdminMemberLayout() {
  return (
    <>
      <SubTitleContainer>
        <MobileSubTitle title="구성원" />
      </SubTitleContainer>
      <Outlet />
    </>
  );
};

