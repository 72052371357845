import { ReactComponent as Circle } from "../assets/icons/circle.svg";

import styled from "styled-components";
import "animate.css";
import ServiceImage from "../assets/images/tax_bg.jpg";
import SubTitle from "./SubTitle";

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  padding-bottom: 140px;
`;

export const ServiceContentContainer = styled.div`
  display: flex;
`;

export const ServiceContent = styled.div`
  line-height: 1.4;
  margin-bottom: 20px;
  width: 50%;
  padding-right:55px;
  font-size: 1.8rem;

  p {
    line-height: 1.8;
    animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    }
    }
  }
`;

export const ServiceListContainer = styled.div`
margin-top: 40px;
display: flex;
flex-direction: column;
gap: 15px;
width: 50%;
animation: fadeInDown 1.5s forwards;

  @keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  }
`;

export const ServiceListTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  
> img{
  width: 10px;
  height: 10px;
}

> h5 {
  font-size: 2.1rem;
}
`;

export const ServiceList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 15px;
`;

export const ServiceListItem = styled.li`
>span{
  margin-right: 10px;
}
`;

export const ServiceImageWrapper = styled.div`
  width: 484px;
  height: 524px;
  margin: 0 auto;
  flex: 1;
 
`;

export const ServiceImageItem = styled.img`
 width: 100%;
 height: 100%;
 animation: fadeInRight 1.5s forwards;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
`;

export function Tax() {
  return (
    <ServiceContainer>
      <SubTitle title="세무자문" />
      <ServiceContentContainer>
        <ServiceContent>
          <p>세금은 비즈니스에서 회피할 대상이 아니라 적극적으로 관리할 요소입니다.
            세법 규정과 프랙티스의 입체적인 이해, 적극적이고 창의적인 마인드,
            정확하고 체계적인 사실관계의 파악은 성공적인 조세 자문의 핵심입니다.</p>
          <ServiceListContainer>
            <ServiceListTitle>
              <Circle />
              <h5> 서비스 항목</h5>
            </ServiceListTitle>
            <ServiceList>
              <ServiceListItem><span>-</span>세무조사 업무 대행</ServiceListItem>
              <ServiceListItem><span>-</span>세무조정</ServiceListItem>
              <ServiceListItem><span>-</span>조세불복</ServiceListItem>
            </ServiceList>
          </ServiceListContainer>
        </ServiceContent>
        <ServiceImageWrapper>
          <ServiceImageItem src={ServiceImage} alt="회계감사 이미지" />
        </ServiceImageWrapper>
      </ServiceContentContainer>
    </ServiceContainer >
  );
}


