export const formatDate = (date) => {
  if (typeof date === "string") {
    date = new Date(date); // 문자열을 Date 객체로 변환
  }
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  // const hour = ("0" + date.getHours()).slice(-2);
  // const minute = ("0" + date.getMinutes()).slice(-2);
  const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"][
    date.getDay()
  ];
  return `${year}-${month}-${day} (${dayOfWeek}) `;
};
