import React from "react";
import styled from "styled-components";
import HistoryTitleImg from "../../assets/images/history-title.png";
import MobileSubTitle from "./MobileSubTitle";

const TimelineContainer = styled.div`
  padding: 60px 20px;
  position: relative;

  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: right;
  color: #555;
  font-weight: bold;
  margin-bottom: 30px;
  padding-right: 30px;

  @media (max-width: 700px) {
    padding-right: 15px;
  }

  >span {
    color: #2aa4d5;
    font-weight: bold;
    font-size: 3.5rem;
  }
`;

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
`;

const TimelineLine = styled.div`
  position: absolute;
  left: 110px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #2aa4d5;
  z-index: 1;
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 10px;
  margin: 30px 0;
  position: relative;
  width: 100%;
`;

const Year = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  color: ${props => (props.active ? "#2aa4d5" : "#186fb2")};
  margin-bottom: 10px;
  text-align: center;
  width: 90px;
  flex-shrink: 0;
`;

const Marker = styled.div`
  height: 20px;
  width: 20px;
  min-width: 20px;
  background-color: #ffffff;
  border: ${props => (props.active ? "3px solid #2aa4d5" : "3px solid #186fb2")};
  border-radius: 50%;
  position: relative;
  z-index: 2;
  /* margin: 0 15px; */
`;

const Events = styled.div`
  margin-bottom: 10px;
  color: #555;
  font-size: 1.8rem;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  gap: 15px;
  `;

const EventDate = styled.div`
  font-weight: bold;
  font-size: 2rem;

`;

const HistoryTitleImageWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  right: 10%;

  width: 40px;
  height: 280px;
  opacity: 0.5;

  >img {
    width: 100%;
    height: 100%;
  }
`;

const CompanyTimeline = () => {
  const timelineData = [
    {
      year: "2019",
      events: [
        { date: "11.01", description: "전담 품질관리실과 품질관리위원회 설치" },
        { date: "06.28", description: "길인회계법인의 일부 사업부를 합병(광주분사무소)" },
        { date: "03.29", description: "광교회계법인의 일부 사업부를 합병" },
      ],
    },
    {
      year: "2003",
      events: [{ date: "03.05", description: "회계법인새시대 설립" }],
    },
  ];

  return (
    <TimelineContainer>
      <MobileSubTitle title="연혁" />
      <Title>2003 ~ <span style={{ color: "#2aa4d5", fontWeight: "bold" }}>현재</span></Title>
      <TimelineWrapper>
        <TimelineLine />
        {timelineData.map((item, index) => (
          <TimelineItem key={index}>
            <Year active={index === 0}>{item.year}</Year>
            <Marker active={index === 0} />
            <Events>
              {item.events.map((event, i) => (
                <div key={i}>
                  <EventDate>{event.date}</EventDate>
                  {event.description}
                </div>
              ))}
            </Events>
          </TimelineItem>
        ))}
      </TimelineWrapper>
      <HistoryTitleImageWrapper>
        <img src={HistoryTitleImg} alt="연혁" />
      </HistoryTitleImageWrapper>
    </TimelineContainer>
  );
};

export default CompanyTimeline;

