import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { BASE_URL } from "../../api/client";
import { getMembers } from "../../api/members";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import avatar from "../../assets/images/avatar_3.png";
import BackgroundImage from "../../assets/images/visual_members.jpg";
import MobileSubVisual from "../../components/mobile/MobileSubVisual";
import MobileSubTitle from "../../components/mobile/MobileSubTitle";
import MobileMemberDetailModal from "../../components/mobile/MobileMemberDetailModal";

const MainContent = styled.section`
  padding-top: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const MembersContainer = styled.div`
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

// const SearchBox = styled.form`
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   position: relative;
//   align-self: flex-end;
//   height: 90px;
//   width: 100%;
//   background-color: #f8f8f8;
//   padding: 20px;
//   border-radius: 15px;
//   margin-bottom: 20px;


//   >input{
//     width: 100%;
//     height: 100%;
//     border-radius: 15px;
//     border: 1px solid var(--border-c);
//     padding-left: 25px;
//     padding-right: 110px;
//     background-color: #fff;
//     font-size: 16px;
//   }
// `;

// const SearchButton = styled.button` 
//   position: absolute;
//   right: 30px;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 60px;
//   height: 40%;
//   background-color: var(--primary-c);
//   border-radius: 13px;
//   padding: 7px 5px;

//   >svg {
//     width: 100%;
//     height: 100%;
//   }
// `;

const SearchBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
  transform: translateX(0);
}}

`;

const SearchBox = styled.form`
  height: 46px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  >input{
    width: 240px;
    height: 100%;
    border: 1px solid var(--border-c);
    padding-left: 20px;
    background-color: #fff;
    font-size: 1.7rem;
  }
`;

const SearchButton = styled.button` 
  width:60px;
  height: 100%;
  background-color: var(--primary-c);
  padding: 12px 8px;

  >svg {
    width: 100%;
    height: 100%;
  }
`;

const MemberListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 20px;
  min-height: 100%;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
  transform: translateX(0);
}}

  @media (max-width: 375px) {
    gap: 30px 10px;
  }
`;

const MemberItemWrapper = styled.div`
width: 100%;
max-height: 300px;
overflow: hidden;
cursor: pointer;
`;

const MemberItemImageBox = styled.div`
position: relative;
`;

const MemberItemImageWrapper = styled.div`
  padding: 57.35% 0;
  
  img {
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 270px;
    overflow: hidden;
  }
`;

const MemberDetailButton = styled.button`
position: absolute;
bottom: -15px;
right: 0;
background-color: #bfbfbf;
width:50px;
height: 50px;
border-radius: 50%;
z-index: 10;
padding: 15px;
transition: all 0.3s ease;

@media (max-width: 375px) {
  padding: 10px;
  width: 40px;
  height: 40px;
}

>svg {
  width: 100%;
  height: 100%;
}
`;

const MemberInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`;

const MemberInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  `;

const MemberInfoItemRole = styled.p`
  font-size: 14px;
`;

const MemberInfoItemName = styled.h4`
  font-size: 20px;
`;

export function Members() {
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [activeMenu, setActiveMenu] = useState("전체보기");

  const navigate = useNavigate();

  const menuList = useMemo(() => ["전체보기", "본점", "구로분실", "강남분실", "광주지점"], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/about?menu=전체보기", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);


  const fetchMembers = useCallback(async (name) => {
    setIsLoading(true);
    const data = await getMembers(activeMenu, name);
    const { members } = data;
    const serialMembers = members.map((member, index) => {
      const { createdAt, ...rest } = member;
      return {
        ...rest,
      };
    });
    setMembers(serialMembers);
    setIsLoading(false);
  }, [setMembers, activeMenu]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    if (name.trim() === "") {
      fetchMembers();
    } else {
      fetchMembers(name);
    }
  }

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers, activeMenu, navigate]);


  return (
    <div>
      <MobileSubVisual title="구성원" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent >
        {!isLoading && (
          <MembersContainer>
            <MobileSubTitle title={activeMenu} />
            <SearchBoxWrapper>
              <SearchBox onSubmit={handleSearchSubmit}>
                <input type="text" placeholder="이름 검색" name="name" />
                <SearchButton type="submit">
                  <SearchIcon />
                </SearchButton>
              </SearchBox>
            </SearchBoxWrapper>
            <MemberListContainer>
              {members &&
                members.map((member, index) => (
                  <MemberItemWrapper
                    key={member.id}
                    no={index + 1}
                    {...member}
                  >
                    <MemberItemImageBox>
                      <MemberItemImageWrapper>
                        {member.profile ? (
                          <img
                            src={`${BASE_URL}/image/${member.profile}`}
                            alt={member.name}
                          />
                        ) : (
                          <img src={avatar} alt={member.name} />
                        )}
                      </MemberItemImageWrapper>
                      <MemberDetailButton

                        onClick={() => {
                          setSelectedMemberId(member.id);
                          setIsModalOpen(true);
                        }}
                      >
                        <SearchIcon />
                      </MemberDetailButton>
                    </MemberItemImageBox>
                    <MemberInfoContainer>
                      <MemberInfoItem>
                        <MemberInfoItemRole>{member.role}</MemberInfoItemRole>
                        <MemberInfoItemName>{member.name}</MemberInfoItemName>
                      </MemberInfoItem>
                    </MemberInfoContainer>
                  </MemberItemWrapper>
                ))}
              {isModalOpen && (
                <MobileMemberDetailModal
                  onClose={() => {
                    setIsModalOpen(false);
                    setSelectedMemberId(null);
                  }}
                  memberId={selectedMemberId}
                />
              )}
            </MemberListContainer>
          </MembersContainer>
        )}
      </MainContent>
    </div>
  );
}