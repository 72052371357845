import { forwardRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";

import AccountingImage from "../assets/images/visual_01.png";
import FinanceImage from "../assets/images/visual_02.png";
import TaxImage from "../assets/images/visual_03.png";
import ManageImage from "../assets/images/visual_04.png";

import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react'; // Swiper와 SwiperSlide를 가져옵니다.
import { Autoplay, Pagination } from 'swiper/modules';

const ServiceSection = styled.section`
  width: 100%;
  height: 100%;
  min-height: 1300px;
  font-size: 6rem;
  overflow: hidden;
  position: relative;

  @media (max-width: 1400px) {
    padding:  0 20px;
  }
`;

const ServiceContainer = styled.div`
  width: 100%;
  height: 1237px;
  margin: 0 auto;
  max-width: 1400px;
  padding: 5% 0 0;
  display: flex;
  align-items: center;

  

  @media (max-width: 1400px) {
    padding: 60px 0;
    flex-direction: column;
    gap: 100px;
  }
`;

const ServiceArticleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin-top: -25px;

  &.animate {
    animation: fadeInLeft 1.5s forwards;
  }

  @keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
  }

 
`;

const SectionTitle = styled.div`
  font-size: 20rem;
  font-weight: 800;
  color: #f9f9f9;
  font-style: italic;
  position: absolute;
  top: 0;
  left: 0; 
  transform: translateY(-55%);
  line-height: 1;
  height: 200px;
  z-index: -1;
 
  @media (max-width: 1400px) {
    position: static;
    transform: translateY(0);
  }
 
  `;

const SectionSubTitle = styled.h2`
  font-size: 7.0rem;
  font-weight: 700;
  color: #000;
  
  
`;

const ServiceContent = styled.p`
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.7777;
`;

// const ServiceSliderContainer = styled.div`
//   height: 100%;
// `;

const CustomSwiper = styled(Swiper)`
    width: 100%;
    max-width: 1160px;
    position: absolute;
    right: -14%;
    overflow: inherit;
    overflow-x: clip;

    &.animate {
      animation: fadeInRight 1.5s forwards;
      
      @keyframes fadeInRight {
      from {
        opacity: 0;
        transform: translateX(100px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
        }
      }
    }


    @media (max-width: 1400px) {
    position: static;
  }
  
    @media (max-width: 1600px) {
      max-width: 1000px;
    }

  .swiper-wrapper {
    z-index: 10;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
  }
`;

const CustomSwiperSlide = styled(SwiperSlide)`
  opacity: ${props => props.$isActive ? 1 : 0.5};
  transition: all 0.4s ease-out;
  margin-top: ${props => props.$isActive ? "-45px" : "0"};
`;

const ServiceItemWrapper = styled(Link)`

  > img {
    display: block;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
`;

const ServiceItemNoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: -20px;
  left: 0px;
`;

const ServiceItemNo = styled.span`
  font-size: 12rem;
  color: #fff;
  font-weight: 800;
  opacity: 0.2;
`;

const ServiceItemTitle = styled.p`
  font-size: 2.4rem;
  color: #fefefe;
  font-weight: 700;
  margin-left: -120px;
`;

const PaginationContainer = styled.div`
  margin-top: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1400px) {
    margin-top: 0;
  }
`;

const PaginationButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  gap: 10px;
`;

const PaginationButton = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid #9b9b9b;
  opacity: 0.7;
  border-radius: 50%;
  color: #333;
`;

const PaginationNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.6rem;
`;

const ActiveNumber = styled.span`
  color: var(--primary-c);
  font-weight: 700;
`;

const AllNumber = styled.span`
  color: #9b9b9b;
`;

const services = [
  { no: "01", image: AccountingImage, alt: "회계감사", url: "/services?menu=회계감사" },
  { no: "02", image: TaxImage, alt: "세무자문", url: "/services?menu=세무자문" },
  { no: "03", image: FinanceImage, alt: "재무자문", url: "/services?menu=재무자문" },
  { no: "04", image: ManageImage, alt: "경영자문", url: "/services?menu=경영자문" },
];

export const ServicesSection = forwardRef((props, ref) => {
  const [swiperIndex, setSwiperIndex] = useState(0); // -> 페이지네이션용
  const [swiper, setSwiper] = useState(0); // -> 슬라이드용
  const [activeIndex, setActiveIndex] = useState(1);

  console.log(props.animate);


  const handlePrev = () => {
    swiper?.slidePrev()
  }
  const handleNext = () => {
    swiper?.slideNext()
  }

  const swiperSetting = {
    speed: 1000,
    autoplay: { delay: 3200, disableOnInteraction: false },
    grabCursor: true,
    effect: 'cards',
    loop: true,
    centeredSlides: false,
    waitForTransition: false,
    spaceBetween: 25,
    slidesPerView: 2,
    modules: [Autoplay, Pagination],
    className: "mySwiper",
    onActiveIndexChange: (e) => setSwiperIndex(e.realIndex),
    onSwiper: (e) => { setSwiper(e) },
    breakpoints: {
      1201: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      641: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      421: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },

  }

  return (
    <ServiceSection ref={ref} >
      <ServiceContainer>
        <ServiceArticleContainer className={props.animate ? 'animate' : ''} >
          <SectionTitle>
            SERVICE
          </SectionTitle>
          <SectionSubTitle >SERVICES</SectionSubTitle>
          <ServiceContent>
            회계법인 새시대는 다양하고 차별화된
            여러개의 서비스를 제공합니다.
          </ServiceContent>
          <PaginationContainer>
            <PaginationButtonContainer>
              <PaginationButton onClick={handlePrev}>
                &lt;
              </PaginationButton>
              <PaginationButton onClick={handleNext}>
                &gt;
              </PaginationButton>
            </PaginationButtonContainer>
            <PaginationNumberContainer>
              <ActiveNumber>{String(swiperIndex + 1).padStart(2, '0')}</ActiveNumber>
              <span>|</span>
              <AllNumber>{String(services.length).padStart(2, '0')}</AllNumber>
            </PaginationNumberContainer>
          </PaginationContainer>
        </ServiceArticleContainer>
        <CustomSwiper {...swiperSetting} onSlideChange={() => {
          setActiveIndex(-1);
        }} onSlideChangeTransitionStart={(e) => {
          setActiveIndex(swiperIndex);
          // console.log(swiperIndex);
        }}
          className={props.animate ? 'animate' : ''}
        >
          {services.map((service, index) => (
            <CustomSwiperSlide key={index} $isActive={swiperIndex === index}>
              <ServiceItemWrapper to={service.url}>
                <img src={service.image} alt={service.alt} />
                <ServiceItemNoContainer>
                  <ServiceItemNo>{service.no}</ServiceItemNo>
                  <ServiceItemTitle>{service.alt}</ServiceItemTitle>
                </ServiceItemNoContainer>
              </ServiceItemWrapper>
            </CustomSwiperSlide>
          ))}
        </CustomSwiper>
      </ServiceContainer>
    </ServiceSection>
  )
});

export default ServicesSection;