import { useMemo, useState } from "react";
import KakaoMap from "./kakaoMap";
import styled from "styled-components";
import SubTitle from "./SubTitle";
import MobileKakaoMap from "./mobile/MobileKakaoMap";

const DirectionContainer = styled.div`
 width: 100%;
 height: 100%;
 padding-bottom: 140px;
`;

const DirectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  margin-bottom: 20px;
`;

const DirectionList = styled.ul`
  height: 50px;
  display: flex;
  font-size: 1.5rem;
  border: 1px solid #E4E4E4;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

const DirectionListItem = styled.li`
  border-right: 1px solid #d4d4d4;
  flex: 1;

  &:last-child {
    border-right: none;
  }
`;

const DirectionListItemSpan = styled.span`
 height: 100%;
 display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #fff;
  color: #a4a4a4;

  &:hover{
    color: #fff;
    background-color: var(--primary-c-hover);
   transition: all 0.3s ease;
  } 

  &.active {
    color: #fff;
    background-color: var(--primary-c-hover);
  }
`;

const MapWrapper = styled.div`
  margin-bottom: 20px;
`;

const LocationTitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 40px;
  color: var(--primary-c-hover);
  border-bottom: 2px solid var(--primary-c-hover);
  margin-bottom: 10px;

  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

const InfoContainer = styled.div`
  display: flex;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

`;

// const LocationTitle = styled.h3`
//   font-size: 20px;
//   font-weight: 700;
//   line-height: 40px;
//   color: var(--primary-c-hover);
//   border-bottom: 2px solid var(--primary-c-hover);
// `;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex: 1;
`;

const InfoItemIcon = styled.img`
  width: 100px;
  height: 100px;
`;

const AddressContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 13px;

  >h4{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const ContactContent = styled.div`
  width: 100%;
  display: flex;                                                                     
  flex-direction: column;
  gap: 5px;

  >h4{
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }  
`;


const ContactList = styled.ul`
    display: flex;
    flex-direction: column;
    font-size: 13px;
    gap: 5px;

    >li{
      display: flex;
      align-items: center;
      gap: 5px;
    }
`;

const InfoData = [
  {
    name: "본점",
    address: "서울특별시 영등포구 양산로 107, 인곡빌딩 4층,7층,9층",
    tel: "02-6679-5500, 02-6679-5505",
    email: "ssd402@ssdac.kr, saesidae01@gmail.com"
  },
  {
    name: "구로분실",
    address: "서울특별시 구로구 경인로53길15, 407호(구로동, 업무A동)",
    tel: "0507-1460-5503",
    email: ""
  },
  {
    name: "강남분실",
    address: "서울특별시 강남구 테헤란로38길29, 6층(역삼동, 이원빌딩)",
    tel: "",
    email: ""
  },
  {
    name: "광주지점(분사무소)",
    address: "광주광역시 서구 상무대로759, 4층(치평동, 지산빌딩)",
    tel: "062-373-5573",
    email: ""
  }
]


export default function Directions() {
  const locationList = useMemo(() => ["본점", "구로분실", "강남분실", "광주지점"], []);

  const [activeLocation, setActiveLocation] = useState("본점");
  const activeLocationIndex = useMemo(() => locationList.indexOf(activeLocation), [activeLocation, locationList]);


  const handleLocationClick = (location) => {
    setActiveLocation(location);
  }

  return (
    <DirectionContainer>
      <SubTitle title="오시는 길" />
      <DirectionContent>
        <DirectionList>
          {locationList.map((location, index) => (
            <DirectionListItem key={index}>
              <DirectionListItemSpan className={activeLocation === location ? "active" : ""} onClick={() => handleLocationClick(location)}>{location}</DirectionListItemSpan>
            </DirectionListItem>
          ))}
        </DirectionList>
        <MapWrapper>
          <MobileKakaoMap activeLocation={activeLocation} height={"474px"} />
        </MapWrapper>
        <LocationTitle>{activeLocation}</LocationTitle>
        <InfoContainer>
          <InfoWrapper>
            <InfoItemIcon src={require("../assets/images/map.jpg")} alt="주소" />
            <AddressContent>
              <h4>주소</h4>
              <p><span>-</span>{InfoData[activeLocationIndex].address}</p>
            </AddressContent>
          </InfoWrapper>
          {InfoData[activeLocationIndex].tel && <InfoWrapper>
            <InfoItemIcon src={require("../assets/images/call.jpg")} alt="연락처" />
            <ContactContent>
              <h4>연락처</h4>
              <ContactList>
                <li><p>TEL</p>: {InfoData[activeLocationIndex].tel}</li>
                {InfoData[activeLocationIndex].fax && <li><p>FAX</p>: {InfoData[activeLocationIndex].fax}</li>}
                {InfoData[activeLocationIndex].email && <li><p>E-MAIL</p>: {InfoData[activeLocationIndex].email}</li>}
              </ContactList>
            </ContactContent>
          </InfoWrapper>}
        </InfoContainer>

      </DirectionContent>
    </DirectionContainer>
  );
}

