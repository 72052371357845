import styled from "styled-components";
import GreetingBackgroundImage from "../assets/images/greeting-bg.png";
import SubTitle from "./SubTitle";

export default function Greeting() {
  const GreetingContainer = styled.div`
  padding-bottom: 140px;
  position: relative;
`;

  const GreetingBackground = styled.div`
  background-image: url(${GreetingBackgroundImage});
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
  width: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  animation: fadeInRight 1.5s forwards;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

  @media (max-width: 1840px) {
    margin-right: -20px;
  }
  `;

  const GreetingContent = styled.p`
  font-size: 1.8rem;
  line-height: 1.6;
  color: #000;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

  const GreetingSignature = styled.h3`
  font-family: 'NanumPen', sans-serif;
  font-size: 5rem;
  font-weight: 700;
  color: #4e4e4e;
  padding-left: 300px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 840px) {
    padding-left: 200px;
  }
`;

  return (
    <>
      <GreetingContainer>
        <GreetingBackground />
        <SubTitle title="대표이사 인사말" />
        <GreetingContent>
          안녕하십니까? <br />
          <br />
          2003년 설립된 저희 회계법인은 지난 20여 년간 여러분의 성원과 신뢰를 <br />
          바탕으로 꾸준히 성장해왔습니다. 현재 영등포구 본사를 중심으로 <br />
          2개의 분실과 광주지점을 운영하고 있으며, 20여 명의 공인회계사와 <br />
          함께 최상의 회계 및 세무서비스를 제공하고 있습니다. <br />
          <br />
          특히 당사는 회계감사와 세무서비스 분야에서 차별화된 경쟁력을 <br />
          갖추고 있습니다. 당사 각 팀은 다양한 업종과 규모의 기업에 <br />
          맞춤형 회계감사 및 세무 컨설팅을 제공함으로써 고객의 <br />
          재정 상태를 철저히 분석하고, 안정적인 성장 기반을 마련해 <br />
          드리고자 노력하고 있습니다. 또한 고객의 다양한 요구에 <br />
          응하여 M&A, 사업계획서 작성, 주식평가, 부동산 평가, <br />
          재무 분석, 세무조사 대리 등 다양한 컨설팅 사업을 영유하고 <br />
          있습니다. 이러한 전문성을 바탕으로 2023 사업연도에는 <br />
          매출액 132억 원을 달성하며, 고객 여러분의 신뢰 속에서 <br />
          꾸준한 성장을 이루어냈습니다. <br />
          <br />
          물론 끊임없이 변화하는 회계 및 세무 관련 법령과 규제에 대응하는 <br />
          것은 도전적일 수 있지만, 저희는 이러한 변화를 철저히 분석하고 <br />
          대비하여 고객의 니즈에 맞춘 최적의 솔루션을 제공합니다. <br />
          저희 법인은 고객 여러분의 든든한 재정 파트너로서 더 나은 서비스와 <br />
          가치를 제공하기 위해 끊임없이 노력할 것입니다. <br />
          <br />
          신뢰를 바탕으로 하는 정직한 경영, 고객 이익 우선 경영, 노사 합심 경영, 그리고 변화와 <br />
          혁신을 두려워하지 않는 도전 정신으로 앞으로도 여러분과 함께 성장해 나가겠습니다. <br />
          <br />
          감사합니다. <br />
          <GreetingSignature>회계법인 새시대 <span>대표이사 김택수</span></GreetingSignature>
        </GreetingContent>
      </GreetingContainer>
    </>
  )
}
