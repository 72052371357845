import Client from "./client";

export async function getMembers(workplace, name) {
  let _workplace = workplace;

  if (workplace === "전체보기") {
    _workplace = undefined;
  }

  const response = await Client.User.get("/members", {
    params: { workplace: _workplace, name },
  });
  return response.data;
}

export async function getMember(id) {
  const response = await Client.User.get(`/members/${id}`);
  return response.data;
}

export async function createMember(member) {
  const response = await Client.Admin.post("/admin/members", member);
  return response.data;
}

export async function updateMemberOrder(id, index) {
  const response = await Client.Admin.patch(`/admin/members/${id}/order`, {
    index,
  });
  return response.data;
}

export async function updateMember(id, member) {
  const response = await Client.Admin.put(`/admin/members/${id}`, member);
  return response.data;
}

export async function deleteMember(id) {
  const response = await Client.Admin.delete(`/admin/members/${id}`);
  return response.data;
}
