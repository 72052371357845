import styled from "styled-components";
import SubTitle from "./SubTitle";
import HistoryBackgroundImage from "../assets/images/history-bg.jpg";

const HistoryContainer = styled.div`
  padding-bottom: 140px;
  height: 1655px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 822px;
  overflow: hidden;
  animation: zoomIn 1.5s forwards;

  @keyframes zoomIn {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1);
    }
}
`;

const HistoryBackground = styled.div`
background-image: url(${HistoryBackgroundImage});
background-size: cover;
background-position: center top;
background-repeat: no-repeat;
width: 100%;
height: 100%;
position: absolute;
top: 80px;
z-index: -1;

@media (max-width: 1440px) {
  top: 65px;
}

`;

const HistoryContent = styled.div`
font-size: 1.8rem;
line-height: 1.6;
color: #000;
width: 100%;
height: 100%;
position: relative;
animation: zoomIn 2s forwards;
`;

const YearContainer = styled.ul`
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
`;

const YearItem = styled.li`
  font-size: 5.5rem;
  font-weight: 800;
  color: #fff;

  &:last-child {
    margin-top: 530px;
  }
`;


const HistoryTextRightItemWrapper = styled.div`
  width: 100%;
  margin-top: 355px;
  line-height: 60px;
  font-size: 2.5rem;
  font-weight: 700; 


  @media (max-width: 1400px) {
    font-size: 1.8rem;
  }
`;

const HistoryRightTextItem = styled.div`
  width: 100%;
  transition: all 0.3s ease-in;

 
  >span {
    padding-left: 52%;
    color: var(--primary-c-hover);
    font-size: 4rem;
    vertical-align: bottom;
    font-weight: 700;
    margin-right: 10px;
    transition: all 0.3s ease-in;


    @media (max-width: 1400px) {
      font-size: 2.5rem;
      margin-right: 5px;
    }
  }


`;

const HistoryTextLeftItemWrapper = styled.div`
  width: 100%;
  margin-top: 435px;
  line-height: 60px;
  font-size: 2.5rem;
  font-weight: 700;
  text-align: right;

  @media (max-width: 1400px) {
    font-size: 1.8rem;
  }
`;

const HistoryLeftTextItem = styled.div`
  width: 100%;
  transition: all 0.3s ease-in;
  margin-bottom: 60px;

  >span {
    padding-right: 52%;
    color: var(--primary-c);
    font-size: 4rem;
    vertical-align: bottom;
    font-weight: 700;
    margin-left: 10px;
    transition: all 0.3s ease-in;

    @media (max-width: 1400px) {
      font-size: 2.5rem;
      margin-left: 8px;
    }
  }
`;

export default function History() {

  return (
    <HistoryContainer>
      <HistoryBackground />
      <SubTitle title="연혁" />
      <HistoryContent>
        <YearContainer>
          <YearItem>2003</YearItem>
          <YearItem>2019</YearItem>
        </YearContainer>
        <HistoryTextRightItemWrapper>
          <HistoryRightTextItem><span>03</span>설립</HistoryRightTextItem>
        </HistoryTextRightItemWrapper>
        <HistoryTextLeftItemWrapper>
          <HistoryLeftTextItem>광교회계법인의 일부 사업부를 합병<span>03</span></HistoryLeftTextItem>
          <HistoryLeftTextItem>길인회계법인의 일부 사업부를 합병(광주분사무소)<span>06</span></HistoryLeftTextItem>
          <HistoryLeftTextItem>전담 품질관리실과 품질관리위원회 설치<span>11</span></HistoryLeftTextItem>
        </HistoryTextLeftItemWrapper>
      </HistoryContent>
    </HistoryContainer>
  );
}