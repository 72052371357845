import { useEffect, useMemo, useState } from "react";
import { login as loginApi } from "../../../api/login";
import { isLoginState, rememberMeState, tokenState, userIdState, usernameState } from "../../../store/atom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Logo from "../../../assets/images/logo_login.png";
import UsernameIcon from "../../../assets/icons/username.svg";
import PasswordIcon from "../../../assets/icons/password.svg";
import UserIcon from "../../../assets/icons/login_user.svg";
import CheckIcon from "../../../assets/icons/check.svg";
import styled from "styled-components";
import LoginBackground from "../../../assets/images/visual_login.png";

const LoginContainer = styled.div`
  background-image: url(${LoginBackground});
  background-size: cover;
  background-position: center;
  padding-top: 228px;
  padding-bottom: 100px;
  min-width:375px;
  min-height: 100vh;
`;

const LoginForm = styled.form`
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 365px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`;

const LoginFormBackground = styled.div`
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.67);
  opacity: 0.7;
  box-shadow: 8px 13px 21px #0000008c;
`;

const LoginLogoLink = styled(Link)`
  background-image:url(${Logo});
  position: absolute;
  bottom: calc(100% + 83px);
  left: 50%;
  transform: translateX(-50%);
  width: 280px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const LoginTitleContainer = styled.div`
  position: relative;
  padding-top: 33px;
  width: 100%;
  height: 157px;
  display: flex;
  justify-content: center;
`;

const LoginTitleBackground = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2aa4d5;
  opacity: 0.7;
`;

const LoginTitle = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  padding: 0 20px;
`;

const LoginIconContainer = styled.div`
  position: absolute;
  top: 85px;
  width: 89px;
  height: 89px;
  border-radius: 50%;
  background-color: #fff;
`;

const LoginIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 48px;
`;

const LoginInputContainer = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 25px;
`;

const LoginInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const LoginInput = styled.input`
  padding: 0 20px 0 61px;
  width: 100%;
  height: 50px;
  border: none;
  border-radius: 5px;
  font-size: 16px;

  ::placeholder {
    color: #9c9a9b;
    font-family: "NanumSquare", sans-serif;
  opacity: 1;
  }
:-ms-input-placeholder {
  font-family: "NanumSquare", sans-serif;
}
::-ms-input-placeholder {
  font-family: "NanumSquare", sans-serif;
}
`;

const UsernameIconImg = styled.img`
  width: 25px;
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
`;

const PasswordIconImg = styled.img`
  width: 1.8rem;
  position: absolute;
  top: 50%;
  left: 1.8rem;
  transform: translateY(-50%);
`;

const CheckboxContainer = styled.div`
  width: 100%;
  padding-left: 30px;
  margin-top: 24px;
  font-size: 1.8rem;
  height: 30px;
  color: #333333;

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  input[type="checkbox"] + label::before {
    display: block;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 6px;
    color: #fff;
  }

  input[type="checkbox"]:checked + label::before {
    background-image: url(${CheckIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-color: #2aa4d5;
    background-size: 70%;
  }
`;

const LoginButtonContainer = styled.div`
  margin-top: 24px;
  padding: 0 20px;
  padding-bottom: 42px;
  width: 100%;
`;

const LoginButton = styled.button`
  width: 100%;
  line-height: 50px;
  font-size: 1.8rem;
  color: #fff;
  background-color: #2aa4d5;
  border-radius: 6px;
`;

export function Login() {
  const [rememberMe, setRememberMe] = useRecoilState(rememberMeState);
  const [localUsername, setLocalUsername] = useRecoilState(usernameState);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const setToken = useSetRecoilState(tokenState);
  const setUserId = useSetRecoilState(userIdState);
  const isLogin = useRecoilValue(isLoginState);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const { Username, Password } = Object.fromEntries(formData);

    if (!validator.Username(Username) || !validator.Password(Password)) {
      return;
    }

    // const res = await postLogin(username, password);

    const res = await loginApi(Username, Password);

    if (!res) {
      alert("Internal server error");
      return;
    }

    const { token, id } = res;

    console.log(res);

    if (!token || !id) {
      alert("Invalid username or password");
      return;
    }
    if (rememberMe) {
      setLocalUsername(username);
    } else {
      setLocalUsername("");
    }
    setToken(token);
    setUserId(id);
    navigate("/admin");
  };

  const validator = useMemo(() => {
    return {
      Username: (value) => value.trim().length > 0,
      Password: (value) => value.trim().length > 0,
    };
  }, []);

  useEffect(() => {
    if (rememberMe && localUsername) {
      setUsername(localUsername);
    } else {
      setLocalUsername("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (isLogin) {
    return <Navigate to="/admin" replace />;
  }

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}>
        <LoginLogoLink to="/" />
        <LoginTitleContainer>
          <LoginTitle>
            관리자페이지에 접근하려면 로그인해주세요.
          </LoginTitle>
          <LoginIconContainer>
            <LoginIcon src={UserIcon} alt="유저 아이콘" />
          </LoginIconContainer>
          <LoginTitleBackground />
        </LoginTitleContainer>
        <LoginInputContainer>
          <LoginInputWrapper>
            <UsernameIconImg src={UsernameIcon} alt="유저 아이콘" />
            <LoginInput
              type="text"
              name="Username"
              required
              placeholder="Username"
              autoComplete="off"
              value={username}
              onChange={(e) => setUsername(e.target.value)} />
          </LoginInputWrapper>
          <LoginInputWrapper >
            <PasswordIconImg src={PasswordIcon} alt="비밀번호 아이콘" />
            <LoginInput
              type="password"
              name="Password"
              required
              placeholder="Password"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)} />
          </LoginInputWrapper>
        </LoginInputContainer>
        <CheckboxContainer>
          <input
            type="checkbox"
            name="RememberMe"
            id="RememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label htmlFor="RememberMe">Remember me</label>
        </CheckboxContainer>
        <LoginButtonContainer>
          <LoginButton type="submit">Login</LoginButton>
        </LoginButtonContainer>
        <LoginFormBackground />
      </LoginForm >
    </LoginContainer >
  );
}
