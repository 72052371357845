import { useCallback, useEffect, useRef, useState } from "react";
import { getMember } from "../api/members";
import { BASE_URL } from "../api/client";
import styled from "styled-components";
import { ReactComponent as NoImageIcon } from "../assets/icons/add_photo_alternate.svg";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  height: auto;
  background-color: white;
  border-radius: 30px;
  padding: 25px;
`;

const ModalTitle = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 48px;
  padding-bottom: 15px;
  border-bottom: 3px solid #186FB2;
  color: #186FB2;
`;

const MemberInfoForm = styled.form`
  display: flex;
  gap: 20px;
  padding-top: 27px;
  width: 100%;

`;

const MemberProfileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;

  >h3 {
    font-size: 2.4rem;
    font-weight: 700;
  }
`;

const ProfileImageWrapper = styled.div`
  width: 210px;
  height: 260px;
  border: 1px solid #C2C3C4;
  border-radius: 10px;
 
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ProfileImage = styled.img`
  display: ${({ $hasImage }) => $hasImage ? "block" : "none"};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProfileImageArea = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px;
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.4;

  >svg {
    width: 80px;
    height: 80px;
  }
`;


const SelectedFileLabel = styled.label`
  line-height: 52px;
  border-radius: 5px;
  background-color: #3D3D3D;
  font-size: 1.8rem;
  font-weight: 700;
  color: white;
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    background-color: #2D2D2D;
  }
`;

const SelectedFileInput = styled.input`
  font-size: 1.6rem;
  width: 100%;
  color: #9B9B9B;
  &::file-selector-button {
    display: none;
  }
`;

const MemberInfoContainer = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 15px;
  width: 600px;
  box-sizing: border-box;
`;

const MemberInfoItemContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

`;

const MemberInfoItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 2rem;
  width: 100%;

  label {
    width: 74px;
    font-size: 1.8rem;
    font-weight: 700;
    color: #303030;
  }

  select {
    flex: 1;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #C2C3C4;
    padding: 0 15px;
    font-size: 1.6rem;
    line-height: 20px;
    color: #303030;
  }
`;

const MemberInfoInput = styled.input`
  flex: 1;
  height: 48px;
  border-radius: 10px;
  border: 1px solid #C2C3C4;
  padding: 0 15px;
  font-size: 1.6rem;
  line-height: 20px;
  color: #303030;

  &::placeholder {
    color: #9B9B9B;
  }
`;

const MemberInfoTextarea = styled.textarea`
  flex: 1;
  height: 104px;
  border-radius: 10px;
  border: 1px solid #C2C3C4;
  padding: 10px 15px;
  font-size: 1.6rem;
  line-height: 30px;
  
  &::placeholder {
    color: #9B9B9B;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

const Button = styled.button`
  width: 100px;
  height: 52px;
  border-radius: 15px;
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
  cursor: pointer;
`;

const DeleteButton = styled(Button)`
background-color: #3d3d3d;
  &:hover {
  background-color: #2d2d2d;
}
`;

const CancelButton = styled(Button)`
background-color: #00a7db;
  &:hover {
  background-color: #0087b3;
}
`;


export default function CreateMemberModal({ onSave, onClose, memberId }) {
  const [profile, setProfile] = useState(undefined);
  const [file, setFile] = useState(null);
  const [hasImage, setHasImage] = useState(false);

  const imgRef = useRef(null);
  const fileRef = useRef(null);
  const formRef = useRef(null);


  const isEditing = memberId !== -1;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const { profile: _, ...member } = Object.fromEntries(formData);
    await onSave({
      ...member,
      profile: profile,
      id: isEditing ? memberId : undefined,
    });
  };

  const handleProfileChange = async (e) => {
    const _file = e.target.files[0];
    console.log(_file);
    if (!_file && file && fileRef.current) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileRef.current.files = dataTransfer.files;
      return;
    }
    setFile(_file);
    const profile = _file?.name ? await convertFileToBase64(_file) : "";
    console.log("profile", profile);
    if (profile) setHasImage(true);

    const img = imgRef.current;
    if (img) img.src = profile;
    setProfile(profile);
  };

  const fetchMember = useCallback(async () => {
    const data = await getMember(memberId);
    const { profile, ...member } = data.member;

    if (profile) {
      setHasImage(true);
      const img = imgRef.current;
      if (img) img.src = `${BASE_URL}/image/${profile} `;
    }

    console.log(member, imgRef.current, profile);
    Object.entries(member).forEach(([key, value]) => {
      const input = formRef.current.querySelector(`input[name="${key}"], textarea[name="${key}"], select[name="${key}"]`);
      if (input) input.value = value;
    });
  }, [memberId]);

  useEffect(() => {
    if (isEditing) {
      console.log("fetchMember", memberId);
      fetchMember();
    }
  }, [isEditing, fetchMember]);

  async function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  }

  return (
    <ModalContainer>
      <ModalContent>
        <ModalTitle>{isEditing ? "구성원 수정" : "구성원 추가"}</ModalTitle>
        <MemberInfoForm ref={formRef} onSubmit={handleSubmit}>
          <MemberProfileWrapper>
            <h3>프로필 이미지</h3>
            <ProfileImageWrapper htmlFor="file">
              <ProfileImage
                ref={imgRef}
                alt="profile"
                $hasImage={hasImage}
              />
              {!hasImage &&
                <ProfileImageArea htmlFor="file">
                  <NoImageIcon />
                  프로필 이미지를 <br /> 추가해주세요.
                </ProfileImageArea>
              }
            </ProfileImageWrapper>
            <SelectedFileLabel htmlFor="file">
              파일 선택
            </SelectedFileLabel>
            <SelectedFileInput type="file" id="file" name="profile" onChange={handleProfileChange} />
          </MemberProfileWrapper>
          <MemberInfoContainer>
            <MemberInfoItemContainer>
              <MemberInfoItemWrapper>
                <label>이름</label><MemberInfoInput type="text" name="name" required />
              </MemberInfoItemWrapper>
              <MemberInfoItemWrapper>
                <label style={{
                }}>직급/직책</label><MemberInfoInput type="text" name="role" required />
              </MemberInfoItemWrapper>
            </MemberInfoItemContainer>
            <MemberInfoItemContainer>
              <MemberInfoItemWrapper>
                <label>근무지</label>
                <select name="workplace">
                  <option value="본점">본점</option>
                  <option value="구로분실">구로분실</option>
                  <option value="강남분실">강남분실</option>
                  <option value="광주지점">광주지점</option>
                </select>
              </MemberInfoItemWrapper>
              <MemberInfoItemWrapper>
                <label style={{
                }}>전화번호</label>
                <MemberInfoInput type="tel" name="phone" required />
              </MemberInfoItemWrapper>
            </MemberInfoItemContainer>
            <MemberInfoItemWrapper>
              <label>이메일</label><MemberInfoInput type="email" name="email" required />
            </MemberInfoItemWrapper>
            <MemberInfoItemWrapper>
              <label>서비스</label><MemberInfoTextarea type="text" name="service" />
            </MemberInfoItemWrapper>
            <MemberInfoItemWrapper>
              <label>학력</label><MemberInfoTextarea type="text" name="education" />
            </MemberInfoItemWrapper>
            <MemberInfoItemWrapper>
              <label>경력</label><MemberInfoTextarea type="text" name="career" />
            </MemberInfoItemWrapper>
            <ButtonContainer>
              <CancelButton type="submit">저장</CancelButton>
              <DeleteButton type="button" onClick={onClose}>
                닫기
              </DeleteButton>
            </ButtonContainer>
          </MemberInfoContainer>
        </MemberInfoForm>
      </ModalContent>
    </ModalContainer>
  );
}