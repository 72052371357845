import { useState } from "react";
import { updatePassword } from "../api/users";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalInner = styled.form`
  width: 540px;
  height: auto;
  border: 10px solid #dddddd;
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 30px;
  color: #303030;
  width: 100%;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  span {
    font-size: 1.8rem;
    font-weight: 700;
  }

  input {
    width: 60%;
    height: 52px;
    border: 1px solid #c2c3c4;
    border-radius: 10px;
    padding: 0 10px;
    font-size: 1.8rem;

    &:focus {
      outline-color: #00a7db;
    }

    &::placeholder {
      color: #9b9b9b;
    }
  }
`;

const CurrentPassword = styled(Label)`
  margin-bottom: 40px;
`;

const NewPassword = styled(Label)`
  margin-bottom: 14px;
`;

const NewPasswordCheck = styled(Label)`
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const Button = styled.button`
  height: 52px;
  padding: 0 35px;
  border-radius: 13px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 15px;
`;

const CancelButton = styled(Button)`
  background-color: #3d3d3d;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #2d2d2d;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #00a7db;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0087b3;
  }
`;

const Required = styled.span`
  position: absolute;
  right: calc(100% + 6.5px);
  color: #ff6666;
`;

const Error = styled.span`
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #ff6666;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
`;

export default function UpdatePasswordModal({ onClose }) {
  const [error, setError] = useState({
    location: "",
    message: "",
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");

  const handleChange = (e) => {
    setError({
      location: "",
      message: "",
    });
    switch (e.target.name) {
      case "currentPassword":
        setCurrentPassword(e.target.value);
        break;
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "newPasswordCheck":
        setNewPasswordCheck(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleConfirm = async (event) => {
    event.preventDefault();
    setError({
      location: "",
      message: "",
    });

    if (currentPassword.trim() === "" || newPassword.trim() === "") {
      return;
    }

    if (newPassword !== newPasswordCheck) {
      setError({
        location: "newPasswordCheck",
        message: "비밀번호가 일치하지 않습니다.",
      });
      return;
    }

    try {
      const res = await updatePassword(currentPassword, newPassword);

      console.log(res, "res");
      if (!res) {
        setError({
          location: "currentPassword",
          message: "비밀번호가 올바르지 않습니다.",
        });
        return;
      }
      alert("비밀번호가 변경되었습니다.");
      onClose();
    } catch (error) {
      setError({
        location: "currentPassword",
        message: "비밀번호가 올바르지 않습니다." || error.response?.data?.message,
      });
    }
  };

  return (
    <ModalContainer>
      <ModalInner onSubmit={handleConfirm}>
        <ModalContent>
          <CurrentPassword>
            <Required>*</Required>
            <span>현재 비밀번호</span>
            <input
              type="password"
              value={currentPassword}
              name="currentPassword"
              onChange={handleChange}
              placeholder="현재 비밀번호"
              required
            />
            {error.location === "currentPassword" && (
              <Error>{error.message}</Error>
            )}
          </CurrentPassword>
          <NewPassword>
            <Required>*</Required>
            <span>변경 비밀번호</span>
            <input
              type="password"
              required
              name="newPassword"
              placeholder="변경 비밀번호"
              value={newPassword}
              onChange={handleChange}
            />
          </NewPassword>
          <NewPasswordCheck>
            <Required>*</Required>
            <span>비밀번호 확인</span>
            <input
              type="password"
              required
              name="newPasswordCheck"
              placeholder="변경 비밀번호 재입력"
              value={newPasswordCheck}
              onChange={handleChange}
            />
            {error.location === "newPasswordCheck" && (
              <Error>{error.message}</Error>
            )}
          </NewPasswordCheck>
        </ModalContent>
        <ButtonContainer>
          <DeleteButton type="submit">확인</DeleteButton>
          <CancelButton onClick={onClose}>취소</CancelButton>
        </ButtonContainer>
      </ModalInner>
    </ModalContainer>
  );
};
