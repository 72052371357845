import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import topIcon from "../assets/icons/top.svg";
import styled from "styled-components";

const ScrollToTopButton = styled.button`
  position: fixed;
  bottom: 30px;
  right: 20px;
  color: #353535;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 9px 10px 11px;
  z-index: 1000;

  svg {
    width: 30px;
    height: 30px;
  }
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const isMain = useLocation().pathname === "/";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(!isMain);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMain]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    isVisible && (
      <ScrollToTopButton onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <img src={topIcon} alt="Top" />
      </ScrollToTopButton>
    )
  );
};

export default ScrollToTop;