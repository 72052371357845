import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  width: 70%;
  max-width: 450px;
  min-width: 300px;
  border: 10px solid #dddddd;
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
  gap: 25px;
`;

const ModalTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  > span {
    color: #2aa4d5;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const Button = styled.button`
  height: 40px;
  padding: 0 25px;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
`;

const CancelButton = styled(Button)`
  background-color: #3d3d3d;
  &:hover {
    background-color: #2d2d2d;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #00a7db;
  &:hover {
    background-color: #0087b3;
  }
`;

const MobileConfirmDeleteModal = ({
  onConfirm,
}) => {
  return (
    <ModalContainer>
      <ModalContent>
        <ModalTitle>
          <span>삭제</span> 하시겠습니까?
        </ModalTitle>
        <ButtonContainer>
          <DeleteButton onClick={() => onConfirm(true)}>확인</DeleteButton>
          <CancelButton onClick={() => onConfirm(false)}>취소</CancelButton>
        </ButtonContainer>
      </ModalContent>
    </ModalContainer>
  );
};

export default MobileConfirmDeleteModal;
