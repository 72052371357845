import styled from "styled-components";
import LogoGray from "../assets/images/logo_footer.png";
import { Link } from "react-router-dom";
import { forwardRef } from "react";

const FooterContainer = styled.footer`
  width: 100%;
  height: auto;
  padding: 60px 20px;
  background-color: #353535;
  color: #fff;
`;

const FooterLogo = styled.img`
  width: 150px;
`;

const FooterInner = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between; 
  gap: 20px;
  position: relative;
`;

const FooterContent = styled.p`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 20px;
`;

const Divider = styled.span`
  margin: 0 10px;
`;

const FooterContentText = styled.span``;

const FooterBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 70px;
`

const FooterCopyright = styled.span`
  display: flex;
  gap: 19px;
  flex-direction: column;
  color: #fff;
  font-weight: 300;
  font-size: 1.4rem;

  padding-top: 30px;
`;

const PersonalInformation = styled.div`
  display: flex;
  gap: 10px;
  font-size: 1.4rem;
  font-weight: 300;
`

const FooterComponent = forwardRef((props, ref) => {

  return (
    <FooterContainer ref={ref}>
      <FooterInner>
        <FooterLogo src={LogoGray} alt="회계법인 새시대" />
        <FooterContent>
          <FooterContentText>
            본점 : 서울특별시 영등포구 양산로 107, 인곡빌딩 4층, 7층, 9층
            <Divider>|</Divider>
            TEL1: 02-2672-2055
            <Divider>|</Divider>
            TEL2: 02-2635-8938
          </FooterContentText>
          <FooterContentText>
            구로분실 : 서울특별시 구로구 경인로 53길 15, 407호 (구로동, 업무A동)
            <Divider>|</Divider>
            TEL : 0507-1460-5503
          </FooterContentText>
          <FooterContentText>
            강남분실: 서울특별시 강남구 테헤란로 38길 29, 6층 (역삼동, 이원빌딩)
          </FooterContentText>
          <FooterContentText>
            광주지점(분사무소): 광주광역시 서구 상무대로 759,4층(치평동,
            지산빌딩)
            <Divider>|</Divider>
            TEL : 062-373-5573
          </FooterContentText>
        </FooterContent>
        <FooterBottomContainer>
          <FooterCopyright>
            Copyright © by 회계법인 새시대 All rights reserved.
          </FooterCopyright>
          <PersonalInformation>
            <Link>
              이용약관
            </Link>
            <span>
              |
            </span>
            <Link>
              개인정보처리방침
            </Link>
          </PersonalInformation>
        </FooterBottomContainer>
      </FooterInner>
    </FooterContainer>
  );
});

export default FooterComponent;
