import { atom, selector } from "recoil";

const localStorageEffect =
  (key) =>
    ({ setSelf, onSet }) => {
      const savedValue = localStorage.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };

export const userIdState = atom({
  key: "userId",
  default: "",
  effects: [localStorageEffect("userId")],
});

export const tokenState = atom({
  key: "token",
  default: "",
  effects: [localStorageEffect("token")],
});

export const isLoginState = selector({
  key: "isLogin",
  get: ({ get }) => get(tokenState) !== "" && get(userIdState) !== "",
});

export const membersState = atom({
  key: "members",
  default: [],
});

export const consultingsState = atom({
  key: "consultings",
  default: [],
});

export const rememberMeState = atom({
  key: "rememberMe",
  default: false,
  effects: [localStorageEffect("rememberMe")],
});
export const usernameState = atom({
  key: "username",
  default: "",
  effects: [localStorageEffect("username")],
});