import { Link } from "react-router-dom";
import styled from "styled-components";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 37px;
  color: #4e4e4e;
  font-size: 2rem;
  font-weight: 400;
  gap: 4px;
  margin-bottom: 80px;
`;

const PaginationButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: ${({ $isActive }) => ($isActive ? "#fff" : "#4e4e4e")};
  background-color: ${({ $isActive }) =>
    $isActive ? "#2AA4D5" : "transparent"};
  border-radius: 5px;
  text-decoration: none;
`;

const PaginationFirst = styled(PaginationButton)`
  margin-right: 20px;
`;
const PaginationLast = styled(PaginationButton)`
  margin-left: 20px;
`;


export const Pagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  onChange,
}) => {
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

  const getPageNumbers = () => {
    let pages = [];
    const halfRange = Math.floor(pageRangeDisplayed / 2);

    if (activePage <= halfRange + 1) {
      // 시작 부분
      pages = Array.from({ length: pageRangeDisplayed }, (_, i) => i + 1);
    } else if (activePage >= totalPages - halfRange) {
      // 끝 부분
      pages = Array.from(
        { length: pageRangeDisplayed },
        (_, i) => totalPages - pageRangeDisplayed + i + 1
      );
    } else {
      // 중간 부분
      pages = Array.from(
        { length: pageRangeDisplayed },
        (_, i) => activePage - halfRange + i
      );
    }

    return pages.filter((page) => page > 0 && page <= totalPages);
  };

  return (
    <PaginationContainer>
      <PaginationFirst
        onClick={(e) => {
          e.preventDefault();
          onChange(1);
        }}
        to={`#`}
      >
        &lt;
      </PaginationFirst>
      {getPageNumbers().map((pageNum) => (
        <PaginationButton
          key={pageNum}
          $isActive={pageNum === activePage}
          onClick={(e) => {
            e.preventDefault();
            onChange(pageNum);
          }}
          to={`#`}
        >
          {pageNum}
        </PaginationButton>
      ))}
      <PaginationLast
        onClick={(e) => {
          e.preventDefault();
          onChange(totalPages);
        }}
        to={`#`}
      >
        &gt;
      </PaginationLast>
    </PaginationContainer>
  );
};