import { Outlet } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import MobileHeader from "./MobileHeader";
import MobileFooter from "./MobileFooter";
const MobileLayout = () => {
  return (
    <div style={{ minWidth: "375px" }}>
      <ScrollToTop />
      <MobileHeader />
      <Outlet />
      <MobileFooter />
    </div>
  );
};

export default MobileLayout;
