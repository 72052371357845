import styled from "styled-components";
import MobileSubTitle from "./MobileSubTitle";
import OrganizationImage from "../../assets/images/organization.png";


const OrganizationContainer = styled.div`
  padding: 60px 20px;
`;

const OrganizationContent = styled.div`
  margin-top: 52px;
  width: 100%;
  height: 500px;

  @media (max-width: 500px) {
    height: 400px;
  }
  @media (max-width: 400px) {
    height: 300px;
  }
`;

const OrganizationImg = styled.div`
 background-image: url(${OrganizationImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  animation: zoomIn 1.5s forwards;

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
`

export default function MobileOrganization() {
  return (
    <>
      <OrganizationContainer>
        <MobileSubTitle title="조직도" />
        <OrganizationContent>
          <OrganizationImg />
        </OrganizationContent>
      </OrganizationContainer>
    </>
  );
}