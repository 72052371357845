import { ServiceContainer, ServiceContent, ServiceImageItem, ServiceImageWrapper, ServiceList, ServiceListContainer, ServiceListItem, ServiceListTitle } from "./MobileAccounting";
import MobileSubTitle from "./MobileSubTitle";
import ServiceImage from "../../assets/images/manage_bg.jpg";
import Circle from "../../assets/icons/circle.svg";

export function MobileManagement() {
  return (
    <ServiceContainer>
      <MobileSubTitle title="경영자문" />
      <ServiceContent>
        <p>회계법인 새시대는 고도화되고 있는 회계환경 하에서 기업이<br />
          신뢰할 수 있는 회계정보를 산출하여 회계투명성을 향상시키고,<br />
          다양한 경영자문을 통하여 고객에게 효과적이고 성공적인 방안을 제시합니다.
        </p>
      </ServiceContent>
      <ServiceListContainer>
        <ServiceListTitle>
          <img src={Circle} alt="" />
          <h5> 서비스 항목</h5>
        </ServiceListTitle>
        <ServiceList>
          <ServiceListItem><span>-</span>개인기업 법인전환</ServiceListItem>
          <ServiceListItem><span>-</span>기업진단</ServiceListItem>
          <ServiceListItem><span>-</span>IFRS 도입 및 자문</ServiceListItem>
          <ServiceListItem><span>-</span>법정관리(개인 및 기업회생)컨설팅</ServiceListItem>
        </ServiceList>
      </ServiceListContainer>
      <ServiceImageWrapper>
        <ServiceImageItem src={ServiceImage} alt="서비스 이미지" />
      </ServiceImageWrapper>
    </ServiceContainer>
  );
}
