import Client from "./client";

export const getUsers = async () => {
  const response = await Client.Admin.get("/admin/users");
  return response.data;
};

export const updatePassword = async (password, newPassword) => {
  const response = await Client.Admin.patch("/admin/users/password", {
    password,
    newPassword,
  });
  console.log(response, "response");
  return response.data;
};
