import { useCallback, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import {
  deleteConsulting,
  getConsultings,
} from "../../../api/consultings";
import { getMembers } from "../../../api/members";
import { formatDate } from "../../../components/Common";
import { MobilePagination } from "../../../components/mobile/MobilePagination";
import MobileSubTitle from "../../../components/mobile/MobileSubTitle";
import { consultingsState } from "../../../store/atom";
import { Container } from "./members";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow.svg";
import MobileConfirmDeleteModal from "../../../components/mobile/MobileConfirmDeleteModal";

const SubTitleContainer = styled.div`
  padding: 60px 20px 0;
`;

const TableContainer = styled.section`
  width: 100%;
`;

const TableEl = styled.table`
  width: 100%;
  border-top: 3px solid #2aa4d5;
  border-bottom: 2px solid #cfd0d1;
`;

const TableHead = styled.thead`
  background-color: #e9f6fb;
  font-size: 14px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: -0.63px;
  color: #4e4e4e;
`;

const TableBody = styled.tbody`
  font-size: 13px;
  font-weight: 400;
  color: #4e4e4e;
`;

const TableRowEl = styled.tr`
  display: flex;
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: none;
  }
`;

const TableBodyRow = styled(TableRowEl)`
  position: relative;

  &:hover {
    background-color: rgba(234, 234, 234, 0.7);
  }

  /* ::after {
    content: ">";
    font-size: 20px;
    color: #4e4e4e;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  } */
`;

const TableHeadItem = styled.th`
 padding-top: 24px;
 padding-bottom: 22px;
`;

const TableHeadItemID = styled(TableHeadItem)`
  flex: 0.1;
`;

const TableHeadItemTitle = styled(TableHeadItem)`
  flex: 0.3;

`;
const TableHeadItemCompany = styled(TableHeadItem)`
  flex: 0.2;
`;

const TableHeadItemAuthor = styled(TableHeadItem)`
  flex: 0.1;
  min-width: 50px;
`;

const TableHeadItemCreatedAt = styled(TableHeadItem)`
  flex: 0.3;
`;

const TableHeadItemManage = styled(TableHeadItem)`
  flex: 0.1;
  justify-content: center;
  min-width: 50px;

`;

const TableHeadItemArrow = styled(TableHeadItem)`
  width: 30px;
  display: flex;
  gap: 10px;
`;


const TableBodyItem = styled.td`
  display: flex;
  align-items: center;
  padding: 21px 0;
  justify-content: center;
`;

const TableEmptyMessage = styled(TableRowEl)`
  justify-content: center;
`;

const TableBodyItemID = styled(TableBodyItem)`
   flex: 0.1;
`;

const TableBodyItemTitle = styled(TableBodyItem)`
  flex: 0.3;
  text-align: center;
  span {
    cursor: pointer;
  }
`;
const TableBodyItemCompany = styled(TableBodyItem)`
  flex: 0.2;
`;

const TableBodyItemAuthor = styled(TableBodyItem)`
  flex: 0.1;
  min-width: 50px;
`;

const TableBodyItemCreatedAt = styled(TableBodyItem)`
  justify-content: center;
  flex: 0.3;
`;

const TableBodyItemManage = styled(TableBodyItem)`
  flex: 0.1;
  min-width: 50px;
`;

const TableBodyItemArrow = styled(TableBodyItem)`
  width: 30px;
  rotate: -90deg;

  > svg {
    width: 24px;
    height: 24px;
  }
`;

const Button = styled.button`
   height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  text-align: center;
`;

const DeleteButton = styled(Button)`
  background-color: #3d3d3d;
`;

export function Consulting() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [consultings, setConsultings] = useRecoilState(consultingsState);
  const [total, setTotal] = useState(0);
  const [size, setSize] = useState(8);
  const page = parseInt(searchParams.get("page") || "1");
  const [detailConsultingId, setDetailConsultingId] = useState(-1);
  const fetchConsultings = useCallback(async () => {
    const data = await getConsultings(page, size);
    setConsultings(data.consultations);
    setTotal(data.total);
  }, [setConsultings, page, size]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = useCallback(
    (page) => {
      setSearchParams({ page: page.toString() });
    },
    [setSearchParams]
  );

  const calcItemsNumberStart = (page) => {
    return (page - 1) * size + 1;
  };

  const handleSizeChange = useCallback(
    (size) => {
      setSearchParams({ page: "1" });
      setSize(size);
    },
    [setSearchParams, setSize]
  );

  // const handleCreateConsulting = useCallback(
  //   async (consulting) => {
  //     await createConsulting(consulting);
  //     fetchConsultings();
  //     setOpenCreateConsultingModal(false);
  //   },
  //   [fetchConsultings]
  // );

  const handleDeleteConsulting = useCallback(
    async (id) => {
      setDetailConsultingId(id);
      setIsOpenDeleteModal(true);
    },
    [fetchConsultings]
  );

  const handleDetailConsulting = (id) => {
    // setOpenDetailConsultingModal(true);
    navigate(`/admin/consulting/${id}`);
    setDetailConsultingId(id);
  };

  const handleConfirmDelete = async (isConfirmed) => {
    setIsOpenDeleteModal(false);
    if (isConfirmed) {
      try {
        await deleteConsulting(detailConsultingId);
        fetchConsultings();
      } catch (error) {
        console.error("상담문의 삭제 실패:", error);
      }
    }
  };

  useEffect(() => {
    fetchConsultings();
  }, [fetchConsultings]);



  useEffect(() => {
    const hasPage = searchParams.get("page");
    if (!hasPage) {
      navigate(`/admin/consulting?page=1`, { replace: true });
    }
  }, [searchParams, navigate]);

  if (page < 1) {
    return <Navigate to="/admin/consulting/1" replace />;
  }

  if (total !== 0 && page > Math.ceil(total / size)) {
    return <Navigate to={`/admin/consulting/${Math.ceil(total / size)}`} replace />;
  }

  return (
    <>
      <SubTitleContainer>
        <MobileSubTitle title="상담문의" />
      </SubTitleContainer>
      <Container>
        {/* <button onClick={() => setOpenCreateConsultingModal(true)}>
        상담문의 추가
      </button> */}
        <TableContainer>
          <TableEl>
            <TableHead>
              <TableRowEl>
                <TableHeadItemID>ID</TableHeadItemID>
                <TableHeadItemTitle>제목</TableHeadItemTitle>
                <TableHeadItemCompany>회사명</TableHeadItemCompany>
                <TableHeadItemAuthor>이름</TableHeadItemAuthor>
                <TableHeadItemCreatedAt>등록일</TableHeadItemCreatedAt>
                <TableHeadItemManage>삭제</TableHeadItemManage>
                <TableHeadItemArrow></TableHeadItemArrow>
              </TableRowEl>
            </TableHead>
            <TableBody>
              {consultings.length === 0 && (
                <TableEmptyMessage>
                  <TableBodyItem>상담문의가 없습니다.</TableBodyItem>
                </TableEmptyMessage>
              )}
              {
                consultings.map((consulting, index) => (
                  <ConsultingRow
                    key={consulting.id}
                    no={calcItemsNumberStart(page) + index}
                    {...consulting}
                    onClick={handleDetailConsulting}
                    onDelete={handleDeleteConsulting}
                  />
                ))}
            </TableBody>
          </TableEl>
        </TableContainer>
        <MobilePagination
          totalItemsCount={total}
          activePage={parseInt(page)}
          itemsCountPerPage={size}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
        {isOpenDeleteModal && (
          <MobileConfirmDeleteModal onConfirm={handleConfirmDelete} />
        )}
      </Container>
    </>

  );
}

// function Pagination({ total, page, size, onChangePage, onChangeSize }) {
//   const totalPage = Math.ceil(total / size);

//   const getPageLinks = () => {
//     const pageLinks = [];
//     const startPage = Math.max(1, page - 2);
//     const endPage = Math.min(totalPage, startPage + 4);

//     for (let i = startPage; i <= endPage; i++) {
//       pageLinks.push(
//         <Link
//           key={i}
//           to={`/admin/consulting/${i}`}
//           style={{ fontWeight: i === page ? "bold" : "normal" }}
//         >
//           {i}
//         </Link>
//       );
//     }

//     return pageLinks;
//   };

//   return (
//     <PaginationContainer>
//       <PaginationFirst
//         onClick={(e) => {
//           e.preventDefault();
//           onChangePage(1);
//         }}
//         to={`#`}
//       >
//         &lt;
//       </PaginationFirst>
//       {getPageLinks().map((pageNum) => (
//         <PaginationButton
//           key={pageNum}
//           $isActive={pageNum === activePage}
//           onClick={(e) => {
//             e.preventDefault();
//             onChange(pageNum);
//           }}
//           to={`#`}
//         >
//           {pageNum}
//         </PaginationButton>
//       ))}
//       <PaginationLast
//         onClick={(e) => {
//           e.preventDefault();
//           onChange(totalPages);
//         }}
//         to={`#`}
//       >
//         &gt;
//       </PaginationLast>
//       <p>
//         {page} / {totalPage}
//       </p>
//       {getPageLinks()}
//       <select value={size} onChange={(e) => onChangeSize(e.target.value)}>
//         <option value={10}>10</option>
//         <option value={20}>20</option>
//         <option value={30}>30</option>
//       </select>
//     </PaginationContainer>
//   );
// }

function CreateConsultingModal({ onSave, onClose }) {
  const [members, setMembers] = useState([]);
  const [memberId, setMemberId] = useState("");
  const formRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const { ...consulting } = Object.fromEntries(formData);
    await onSave({
      memberId,
      ...consulting,
    });
  };
  const handleMemberChange = (e) => {
    setMemberId(e.target.value);
  };
  const fetchMembers = useCallback(async () => {
    const data = await getMembers();
    setMembers(data.members);
  }, [setMembers]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{ width: "500px", height: "500px", backgroundColor: "white" }}
      >
        <h2>상담문의 추가</h2>
        <form ref={formRef} onSubmit={handleSubmit}>
          <input type="text" name="company" placeholder="회사명" required />
          <input type="text" name="author" placeholder="이름" required />
          <input type="tel" name="phone" placeholder="휴대폰 번호" />
          <input type="email" name="email" placeholder="이메일" />
          <select
            name="memberId"
            value={memberId}
            onChange={handleMemberChange}
            required
          >
            <option value="">담당자 선택</option>
            {members &&
              members.map((member) => (
                <option value={member.id} key={member.id}>
                  {member.name} {member.role}
                </option>
              ))}
          </select>
          <input
            type="text"
            name="howToReply"
            placeholder="답변 방법"
            required
          />
          <input type="text" name="title" placeholder="서비스명" required />
          <input type="text" name="content" placeholder="상담 내용" required />
          <button type="submit">추가</button>
          <button onClick={onClose}>닫기</button>
        </form>
      </div>
    </div>
  );
}


function ConsultingRow({ onClick, onDelete, ...props }) {
  return (
    <TableBodyRow
      onClick={() => onClick(props.id)}
      style={{
        cursor: "pointer",
      }}
    >
      <TableBodyItemID>{props.id}</TableBodyItemID>
      <TableBodyItemTitle>{props.title}</TableBodyItemTitle>
      <TableBodyItemCompany>{props.company}</TableBodyItemCompany>
      <TableBodyItemAuthor>{props.author}</TableBodyItemAuthor>
      <TableBodyItemCreatedAt>{formatDate(props.createdAt)}</TableBodyItemCreatedAt>
      <TableBodyItemManage>
        <DeleteButton onClick={(e) => {
          e.stopPropagation();
          onDelete(props.id);
        }}>삭제 </DeleteButton>
      </TableBodyItemManage>
      <TableBodyItemArrow>
        <ArrowIcon />
      </TableBodyItemArrow>
    </TableBodyRow>
  );
};
