import styled from "styled-components";

const ConfirmContainer = styled.div`
  width: 480px;
  height: 230px;
  padding: 15px;
  background-color: #ddd;
  border-radius: 30px;
`;

const ConfirmContainerInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background-color: #fff;
  border-radius: 15px;
  padding-top: 35px;
`;

const ConfirmText = styled.p`
  font-size: 2.2rem;
  line-height: 30px;
  font-weight: 700;

  > span {
    color: #00a5d8;
  } 
`;

const ConfirmButton = styled.button`
  width: 100px;
  height: 46px;
  border-radius: 15px;
  background: #3d3d3d;
  color: #fff;
  font-size: 2rem;
  line-height: 46.76px;
  font-weight: 700;
`;

export default function ConsultCompModal({ onClose }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ConfirmContainer>
        <ConfirmContainerInner>
          <ConfirmText>상담신청이 <span>완료</span>되었습니다.</ConfirmText>
          <ConfirmButton onClick={onClose}>확인</ConfirmButton>
        </ConfirmContainerInner>
      </ConfirmContainer>
    </div>
  );
}