import { useCallback, useEffect, useRef, useState } from "react";
import { getConsultingById, updateConsultationDone, updateConsultationMemberId } from "../../../api/consultings";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { getMembers } from "../../../api/members";
import { formatDate } from "../../../components/Common";
import ArrowIcon from "../../../assets/icons/arrow.svg";
import MobileSubTitle from "../../../components/mobile/MobileSubTitle";
import { Container } from "./members";

const SubTitleContainer = styled.div`
  padding: 60px 20px 0;
  display: flex;
  align-items: flex-end;
  gap: 10px;

  > span {
    margin-bottom: 20px;
    color: var(--primary-c);
    font-weight: 700;
  }
`;



const ConsultingFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  font-size: 16px;
`;

const ConsultItemContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;

  @media (max-width: 375px) {
    flex-direction: column;
  }
`;

const ConsultItemLabel = styled.label`
  width: 100px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  font-size: 18px;
`

const ConsultItemText = styled.p`
  line-height: 30px;
  color: #6d6969;
`

const ConsultItemSelect = styled.select`
  color: #333333;
  width: 200px;
  line-height: 36.76px;
  border: 1px solid #c2c3c4;
  border-radius: 8px;
  padding: 0 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 16px;
  background: url(${ArrowIcon}) no-repeat right 5px center;
  background-size: 24px;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`
const FormItemTextarea = styled.textarea`
  flex: 1;
  height: 200px;
  border: 1px solid var(--border-c);
  border-radius: 8px;
  padding: 20px;
  font-size: 25px;
  color: var(--border-c);
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 50px;
`;

const Button = styled.button`
  width: 100%;
  height: 46px;
  border-radius: 10px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
`;

const CancelButton = styled(Button)`
  background-color: #3d3d3d;
  &:hover {
    background-color: #2d2d2d;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #00a7db;
  &:hover {
    background-color: #0087b3;
  }
`;

export function DetailConsulting() {
  const { id } = useParams();
  const [consulting, setConsulting] = useState({});
  const [done, setDone] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [members, setMembers] = useState([]);

  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current && memberId) {
      selectRef.current.focus();
    }
  }, [selectRef, memberId]);

  const fetchConsulting = useCallback(async () => {
    const data = await getConsultingById(id);
    setConsulting(data.consultation);
    setMemberId(data.consultation.memberId || null);
    setDone(data.consultation.done);
  }, [id]);

  const navigate = useNavigate();

  const handleUpdateConsultingDone = async (e) => {
    try {
      e.preventDefault();
      await updateConsultationDone(id, done);
      await updateConsultationMemberId(id, memberId);
      fetchConsulting();
      alert("상담문의를 저장하였습니다.")
      navigate("/admin/consulting");
    } catch (error) {
      console.error(error);
      alert("저장에 실패했습니다.");
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    fetchConsulting();
  }, [fetchConsulting]);
  if (!consulting) return null;



  const fetchMembers = async () => {
    const data = await getMembers();
    console.log(data);
    setMembers(data.members);
  };

  return (
    <>
      <SubTitleContainer>
        <MobileSubTitle title="상담문의" />
        <span>&gt; 상세보기</span>
      </SubTitleContainer>
      <Container>
        <ConsultingFormContainer onSubmit={handleUpdateConsultingDone}>
          <ConsultItemContainer>
            <ConsultItemLabel>ID</ConsultItemLabel><ConsultItemText>{consulting.id}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>등록일</ConsultItemLabel><ConsultItemText>{consulting.createdAt ? formatDate(consulting.createdAt) : "-"}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>회사명</ConsultItemLabel> <ConsultItemText> {consulting.company}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>이름</ConsultItemLabel><ConsultItemText>{consulting.author}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>연락처</ConsultItemLabel>
            <ConsultItemText>{consulting.phone}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>이메일</ConsultItemLabel>
            <ConsultItemText>{consulting.email ? consulting.email : "-"}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel >담당자</ConsultItemLabel>
            <ConsultItemSelect
              ref={selectRef}
              value={memberId}
              onChange={({ target }) => setMemberId(target.value)}
              className={memberId ? "member-selected" : ""}
            >
              <option value={1}>담당자를 선택해주세요.</option>
              {members.map((member) => (
                <option key={member.id} value={member.id}>{member.name} {member.role}</option>
              ))}
            </ConsultItemSelect>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>답변 방법</ConsultItemLabel>
            <ConsultItemText>{consulting.howToReply}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>서비스명</ConsultItemLabel> <ConsultItemText>{consulting.title}</ConsultItemText>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>문의 내용</ConsultItemLabel>
            <FormItemTextarea disabled>{consulting.content}</FormItemTextarea>
          </ConsultItemContainer>
          <ConsultItemContainer>
            <ConsultItemLabel>
              답변 여부
            </ConsultItemLabel>
            <ConsultItemSelect
              name="done"
              value={done}
              onChange={(e) => setDone(e.target.value)}
            >
              <option value="" key="default">답변 여부를 선택해주세요.</option>
              <option value={false}>진행중</option>
              <option value={true}>완료</option>
            </ConsultItemSelect>
          </ConsultItemContainer>
          <ButtonContainer>
            <DeleteButton type="submit">저장</DeleteButton>
            <CancelButton type="button" onClick={() => navigate("/admin/consulting")}>
              취소
            </CancelButton>
          </ButtonContainer>
        </ConsultingFormContainer>
      </Container >
    </>
  );
}
