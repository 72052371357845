import styled from "styled-components";
import { Outlet } from "react-router-dom";
import SubTitle from "./SubTitle";


export default function AdminMemberLayout() {
  return (
    <>
      <SubTitle title="구성원" />
      <Outlet />
    </>
  );
};

