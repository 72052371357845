import { useState } from "react";
import styled from "styled-components";
import MobileUpdatePasswordModal from "../../../components/mobile/MobilePasswordModal";
import { Container } from "./members";

const SubTitleContainer = styled.div`
  padding: 60px 20px 0;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  color: #2aa4d5;
  font-weight: 700;

  h3 {
    font-size: 22px;
  }

  span {
    font-size: 20px;
  }

  ::before {
    position: absolute;
    left: 0px;
    bottom: calc(100% + 15px);
    content: "";
    display: block;
    width: 25px;
    height: 3px;
    background-color: #186fb2;
  }
`;

const Button = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 58px;
  width: 130px;
  height: 46px;
  border-radius: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  background-color: #00a7db;
  &:hover {
    background-color: #0087b3;
  }
`;

export const Setting = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SubTitleContainer>
        <h3>설정</h3>
        <span>&gt; 비밀번호 변경</span>
      </SubTitleContainer>
      <Container>
        <Button onClick={() => setIsOpen(true)}>변경</Button>
        {isOpen && <MobileUpdatePasswordModal onClose={() => setIsOpen(false)} />}
      </Container>
    </>
  );
};

