
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import MobileSubVisual from "../../components/mobile/MobileSubVisual";
import MobileGreeting from "../../components/mobile/MobileGreeting";
import MobileHistory from "../../components/mobile/MobileHistory";
import MobileOrganization from "../../components/mobile/MobileOrganization";
import MobileDirections from "../../components/mobile/MobileDirections";
import BackgroundImage from "../../assets/images/visual_about.jpg";
import styled from "styled-components";

const MainContent = styled.section`
  padding-top: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export default function About() {

  const [activeMenu, setActiveMenu] = useState("대표이사 인사말");

  const menuList = useMemo(() => ["대표이사 인사말", "연혁", "조직도", "오시는 길"], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/about?menu=대표이사 인사말", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);

  return (
    <div >
      <MobileSubVisual title="회사소개" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent>
        {activeMenu === "대표이사 인사말" && <MobileGreeting />}
        {activeMenu === "연혁" && <MobileHistory />}
        {activeMenu === "조직도" && <MobileOrganization />}
        {activeMenu === "오시는 길" && <MobileDirections />}
      </MainContent>
    </div>
  );
}

