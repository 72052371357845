import { useEffect, useMemo, useState } from "react";
import SubVisual from "../components/SubVisual";
import { Accounting } from "../components/Accounting";
import { Tax } from "../components/Tax";
import { Finance } from "../components/Finance";
import { Management } from "../components/Management";

import { useNavigate, useSearchParams } from "react-router-dom";
import BackgroundImage from "../assets/images/visual_service.jpg";
import styled from "styled-components";

const MainContent = styled.section`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1200px;

  @media (max-width: 1840px) {
    padding: 0 20px;
  }
`;

export function Services() {
  const [activeMenu, setActiveMenu] = useState("회계감사");
  const menuList = useMemo(() => ["회계감사", "세무자문", "재무자문", "경영자문"], []);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();


  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/services?menu=회계감사", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);

  return (
    <div>
      <SubVisual title="서비스" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent>
        {activeMenu === "회계감사" && <Accounting />}
        {activeMenu === "세무자문" && <Tax />}
        {activeMenu === "재무자문" && <Finance />}
        {activeMenu === "경영자문" && <Management />}
      </MainContent>
    </div>
  );
}
