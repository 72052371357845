import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MobileAccounting } from "../../components/mobile/MobileAccounting";
import { MobileTax } from "../../components/mobile/MobileTax";
import { MobileFinance } from "../../components/mobile/MobileFinance";
import { MobileManagement } from "../../components/mobile/MobileManagement";
import MobileSubVisual from "../../components/mobile/MobileSubVisual";
import BackgroundImage from "../../assets/images/visual_service.jpg";
import styled from "styled-components";

const MainContent = styled.section`
  padding-top: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export function Services() {
  const [activeMenu, setActiveMenu] = useState("회계감사");
  const menuList = useMemo(() => ["회계감사", "세무자문", "재무자문", "경영자문"], []);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();


  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/services?menu=회계감사", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);

  return (
    <div>
      <MobileSubVisual title="서비스" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent>
        {activeMenu === "회계감사" && <MobileAccounting />}
        {activeMenu === "세무자문" && <MobileTax />}
        {activeMenu === "재무자문" && <MobileFinance />}
        {activeMenu === "경영자문" && <MobileManagement />}
      </MainContent>
    </div>
  );
}
