import styled from "styled-components";

const SubTitleContainer = styled.div`
  margin-top: 15px;
  position: relative;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

  h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2aa4d5;
  }

  ::before {
    position: absolute;
    left: 0px;
    bottom: calc(100% + 15px);
    content: "";
    display: block;
    width: 25px;
    height: 3px;
    background-color: #186fb2;
  }
`;

const MobileSubTitle = ({ title }) => {
  return (
    <SubTitleContainer>
      <h3>{title}</h3>
    </SubTitleContainer>
  );
};

export default MobileSubTitle;
