import { Outlet } from "react-router-dom";


export default function MobileAdminConsultLayout() {
  return (
    <>
      <Outlet />
    </>
  );
};

