import About from "./about";
import { Consulting } from "./consulting";
import { Members } from "./members";
import MobileNewHome from "./newHome";
import { Services } from "./services";

const Mobile = {
  About,
  Consulting,
  MobileNewHome,
  Members,
  Services
};

export default Mobile;
