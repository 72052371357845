import { useLayoutEffect, useState } from "react";

function useViewport() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleResize = () => {
    const SCREEN_WIDTH = 768;
    const _isMobile =
      window.innerWidth < SCREEN_WIDTH || window.outerWidth < SCREEN_WIDTH;
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setIsMobile(_isMobile);
    if (_isMobile) {
      console.log("mobile");
    }
  };

  useLayoutEffect(() => {
    handleResize();
    setIsLoaded(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    width,
    height,
    isMobile,
    isLoaded,
  };
}

export default useViewport;
