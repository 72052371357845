import styled from "styled-components";

const ConfirmButton = styled.button`
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #3d3d3d;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #2d2d2d;
  }
`;

export default function MobileConsultCompModal({ onClose }) {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <div
        style={{
          boxSizing: "content-box",
          width: "350px",
          height: "200px",
          backgroundColor: "#f9f9f9",
          borderRadius: "15px",
          border: "5px solid #ccc",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "30px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <p className="consult_confirm_text" style={{ fontSize: "18px", fontWeight: "bold" }}>상담신청이 <span className="primary_text_color">완료</span>되었습니다.</p>
        <ConfirmButton onClick={onClose}>확인</ConfirmButton>
      </div>
    </div>
  );
}