import { useCallback, useEffect, useRef, useState } from "react";
import { getConsultingById, updateConsultationDone, updateConsultationMemberId } from "../../api/consultings";
import { useNavigate, useParams } from "react-router-dom";
import SubTitle from "../../components/SubTitle";
import styled from "styled-components";
import { getMembers } from "../../api/members";
import { formatDate } from "../../components/Common";
import ArrowIcon from "../../assets/icons/arrow.svg";

const Container = styled.div`
  margin-bottom: 153px;
`;

const ConsultingForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  padding-left: 10px;
  width: 1200px;
`;

const ConsultingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ConsultItemContainer = styled.div`
  display: flex;
  width: 100%;
`;

const ConsultItemHalfLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 50%;
`;

const ConsultItemHalfRight = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 567px;
`;

const ConsultItemLabel = styled.label`
  font-size: 1.8rem;
  line-height: 46.76px;
  width: 90px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;

  span {
    position: absolute;
    right: calc(100% + 4px);
    width: 10px;
    height: 48.76px;
    line-height: 48.76px;
    color: #ff6666;
    display: block;
  }
`
const ConsultItemText = styled.p`
  flex:1;
  font-size: 1.7rem;
  line-height: 30px;
  color: #6d6969;
  text-align: left;
  padding-left: 10px;
`

const ConsultItemSelect = styled.select`
  width: 230px;
  line-height: 46.76px;
  border: 1px solid #c2c3c4;
  border-radius: 8px;
  padding: 0 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(${ArrowIcon}) no-repeat right 5px center;
  font-size: 1.7rem;
  color: #333;
`

const ConsultServiceItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ConsultContentItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
`;

const FormItemTextarea = styled.textarea`
  width: 948px;
  height: 200px;
  border: 1px solid #9b9b9b;
  border-radius: 8px;
  padding: 10px;
  font-size: 2rem;
  color: var(--border-c);
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 66px;
`;

const Button = styled.button`
  width: 142px;
  height: 50px;
  border-radius: 15px;
  color: #fff;
  font-size: 2.2rem;
  font-weight: 700;
  cursor: pointer;
`;

const CancelButton = styled(Button)`
  background-color: #3d3d3d;
  &:hover {
    background-color: #2d2d2d;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #00a7db;
  &:hover {
    background-color: #0087b3;
  }
`;

export function DetailConsulting() {
  const { id } = useParams();
  const [consulting, setConsulting] = useState({});
  const [done, setDone] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [members, setMembers] = useState([]);

  const selectRef = useRef(null);

  useEffect(() => {
    if (selectRef.current && memberId) {
      selectRef.current.focus();
    }
  }, [selectRef, memberId]);

  const fetchConsulting = useCallback(async () => {
    const data = await getConsultingById(id);
    setConsulting(data.consultation);
    setMemberId(data.consultation.memberId || null);
    setDone(data.consultation.done);
  }, [id]);

  const navigate = useNavigate();

  const handleUpdateConsultingDone = async (e) => {
    try {
      e.preventDefault();
      if (!memberId) {
        alert("담당자를 선택해주세요.");
        return;
      }
      await updateConsultationDone(id, done);
      await updateConsultationMemberId(id, memberId);
      fetchConsulting();
      alert("상담문의를 저장하였습니다.")
      navigate("/admin/consulting");
    } catch (error) {
      console.error(error);
      alert("저장에 실패했습니다.");
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    fetchConsulting();
  }, [fetchConsulting]);
  if (!consulting) return null;



  const fetchMembers = async () => {
    const data = await getMembers();
    console.log(data);
    setMembers(data.members);
  };


  // const handleSubmit = async () => {
  //   let res;
  //   if (isEdit) {
  //     res = await updateNotice(Number(id), title, JSON.stringify(content));
  //   } else {
  //     res = await postNotice(title, JSON.stringify(content), folderName);
  //   }
  //   if (!res) {
  //     alert("Internal server error");
  //     return;
  //   }
  //   alert("공지사항이 저장되었습니다.");

  //   navigate("/admin/notice");
  // };

  return (
    <>
      {/* <div
          style={{ width: "500px", height: "500px" }}
        >
          <h2>상담문의 상세</h2>
          <p>등록일: {consulting.createdAt}</p>
          <p>회사명: {consulting.company}</p>
          <p>이름: {consulting.author}</p>
          <p>휴대폰 번호: {consulting.phone}</p>
          <p>이메일: {consulting.email}</p>
          <p>담당자: {consulting.memberName}</p>
          <p>답변 방법: {consulting.howToReply}</p>
          <p>서비스명: {consulting.title}</p>
          <p>상담 내용: {consulting.content}</p>
          <select
            name="done"
            value={done}
            onChange={(e) => setDone(e.target.value)}
          >
            <option value={true}>답변 완료</option>
            <option value={false}>답변 대기</option>
          </select>
          <button onClick={handleUpdateConsultingDone}>저장</button>
        </div> */}
      <Container>
        <SubTitle title="상담문의" subTitle="상세보기" />
        <ConsultingForm onSubmit={handleUpdateConsultingDone}>
          <ConsultingContainer>
            <ConsultItemContainer>
              <ConsultItemHalfLeft >
                <ConsultItemLabel><span></span>ID</ConsultItemLabel><ConsultItemText>{consulting.id}</ConsultItemText>
              </ConsultItemHalfLeft>
              <ConsultItemHalfRight>
                <ConsultItemLabel><span></span>등록일</ConsultItemLabel><ConsultItemText>{consulting.createdAt ? formatDate(consulting.createdAt) : "-"}</ConsultItemText>
              </ConsultItemHalfRight>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultItemHalfLeft>
                <ConsultItemLabel><span></span>회사명</ConsultItemLabel> <ConsultItemText> {consulting.company}</ConsultItemText>
              </ConsultItemHalfLeft>
              <ConsultItemHalfRight>
                <ConsultItemLabel><span></span>이름</ConsultItemLabel><ConsultItemText>{consulting.author}</ConsultItemText>
              </ConsultItemHalfRight>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultItemHalfLeft>
                <ConsultItemLabel><span></span>연락처</ConsultItemLabel>
                <ConsultItemText>{consulting.phone}</ConsultItemText>
              </ConsultItemHalfLeft>
              <ConsultItemHalfRight>
                <ConsultItemLabel><span></span>이메일</ConsultItemLabel>
                <ConsultItemText>{consulting.email ? consulting.email : "-"}</ConsultItemText>
              </ConsultItemHalfRight>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultItemHalfLeft>
                <ConsultItemLabel ><span>*</span>담당자</ConsultItemLabel>
                <ConsultItemSelect
                  ref={selectRef}
                  value={memberId || ""}
                  onChange={({ target }) => setMemberId(target.value)}
                  className={memberId ? "member-selected" : ""}
                >
                  <option value="">담당자를 선택해주세요.</option>
                  {members.map((member) => (
                    <option key={member.id} value={member.id}>{member.name} {member.role}</option>
                  ))}
                </ConsultItemSelect>
              </ConsultItemHalfLeft>
              <ConsultItemHalfRight>
                <ConsultItemLabel><span></span>답변 방법</ConsultItemLabel>
                <ConsultItemText>{consulting.howToReply}</ConsultItemText>
              </ConsultItemHalfRight>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultServiceItem>
                <ConsultItemLabel><span></span>서비스명</ConsultItemLabel> <ConsultItemText>{consulting.title}</ConsultItemText>
              </ConsultServiceItem>
            </ConsultItemContainer>
            <ConsultItemContainer>
              <ConsultContentItem>
                <ConsultItemLabel><span></span>문의 내용</ConsultItemLabel><FormItemTextarea disabled>{consulting.content}</FormItemTextarea>
              </ConsultContentItem>
            </ConsultItemContainer>
            <ConsultItemHalfLeft>
              <ConsultItemLabel>
                <span>*</span>답변 여부
              </ConsultItemLabel>
              <ConsultItemSelect
                name="done"
                value={done}
                onChange={(e) => setDone(e.target.value)}
              ><span>*</span>답변 여부
                <option value="" key="default">답변 여부를 선택해주세요.</option>
                <option value={false}>진행중</option>
                <option value={true}>완료</option>
              </ConsultItemSelect>
            </ConsultItemHalfLeft>
          </ConsultingContainer>
          <ButtonContainer>
            <DeleteButton type="submit">저장</DeleteButton>
            <CancelButton type="button" onClick={() => navigate("/admin/consulting")}>
              취소
            </CancelButton>
          </ButtonContainer>
        </ConsultingForm>


      </Container >
    </>

  );
}
