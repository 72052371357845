import { useCallback, useEffect, useState } from "react";
import { getMember } from "../../api/members";
import { BASE_URL } from "../../api/client";
import avatar from "../../assets/images/avatar_3.png";
import CloseIcon from "../../assets/icons/close.png";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  width: 90%;
  height: auto;
  background-color: white;
  border-radius: 30px;
`;

const MemberDetailContainer = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding: 20px;
`;

const MemberDetailProfileImageWrapper = styled.div`
  width: 120px;
  height: 150px;
  align-self: flex-start;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const MemberDetailInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  width: 100%;
  height: 100%;

  > h6{
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--primary-c);
    margin-bottom: 13px;
  }

  >h3{
    font-size: 2.5rem;
    font-weight: 700;
    color: #4e4e4e;
    margin-bottom: 10px;
  }
`;

const MemberDetailInfoEmailItem = styled.div`
  border-bottom: 2px solid var(--primary-c-hover);
  line-height: 25px;
  font-size: 1.6rem;
  color: #9b9b9b;
  margin-bottom: 25px;
`;

const MemberDetailInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > h6{
    font-size:2rem;
    font-weight: 700;
    color: var(--primary-c-hover);
  }
`;

const MemberDetailInfoItemContent = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  min-height: 60px;
  padding-left: 10px;
`;

const ConsultingButton = styled.button`
  width: 100%;
  height: 46px;
  border-radius: 10px;
  background-color: var(--primary-c);
  transition: background-color 0.3s ease;
  color: #fff;
  font-size: 16px;

  &:hover {
    background-color: #0089b3;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -23px;
  right: -10px;
  background-color: var(--primary-c);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 15px;

  &:hover {
    background-color: #0089b3;
    box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.3);
  }

  > img {
    width: 100%;
    height: 100%;
  }
 `;

export default function MobileMemberDetailModal({ onClose, memberId }) {
  const navigate = useNavigate();
  const [member, setMember] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const fetchMember = useCallback(async () => {
    const data = await getMember(memberId);
    setMember(data.member);
    console.log(data.member);
  }, [memberId]);

  useEffect(() => {
    fetchMember();
  }, [fetchMember]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const handleConsultingClick = () => {
    navigate(`/consulting?memberId=${member.id}`);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(onClose, 300);
  };

  return (
    <ModalContainer>
      <ModalContent>
        <MemberDetailContainer>
          {/* <MemberDetailProfileImageWrapper>
            {member.profile ?
              (<img
                src={`${BASE_URL}/image/${member.profile}`}
                alt={member.name}
              />)
              : (
                <img src={avatar} alt={member.name} />
              )
            }
          </MemberDetailProfileImageWrapper> */}
          <MemberDetailInfoWrapper>
            <h6>{member.role}</h6>
            <h3>{member.name}</h3>
            <MemberDetailInfoEmailItem>
              <p>{member.email}</p>
            </MemberDetailInfoEmailItem>
            <MemberDetailInfoItem>
              <h6>서비스</h6>
              <MemberDetailInfoItemContent>
                <p>- {member.service}</p>
              </MemberDetailInfoItemContent>
            </MemberDetailInfoItem>
            <MemberDetailInfoItem>
              <h6>학력</h6>
              <MemberDetailInfoItemContent>
                <p>- {member.education}</p>
              </MemberDetailInfoItemContent>
            </MemberDetailInfoItem>
            <MemberDetailInfoItem>
              <h6>경력</h6>
              <MemberDetailInfoItemContent>
                <p>- {member.career}</p>
              </MemberDetailInfoItemContent>
            </MemberDetailInfoItem>
            <ConsultingButton onClick={handleConsultingClick}>상담신청하기</ConsultingButton>
          </MemberDetailInfoWrapper>
          <CloseButton onClick={handleClose}>
            <img src={CloseIcon} alt="close" />
          </CloseButton>
        </MemberDetailContainer>
      </ModalContent>
    </ModalContainer>
  );
}
