import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Triangle from "../../assets/icons/triangle.svg";
import HomeIcon from "../../assets/icons/home.png";

const TitleSection = styled.section`
  background-image: url(${(props) => props.$backgroundImage});
  background-size: cover;
  background-position: 80% 50%;
  background-repeat: no-repeat;
  margin-top: -80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  >span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

const TitleSectionInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  background: rgb(0, 0, 0, 0.1);
  color: #fff;
  width: 100%;
  height: 360px;
`;

const TitleH2 = styled.h2`
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.05em;
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  font-size: 14px;
`;

const HomeIconImg = styled.img`
  cursor: pointer;
  width: 18px;
`;

// Start of Selection
const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 22px;
  img {
    width: 10px;
  }
`;

const SubMenuBarContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  background-color:#00a5d8b3;
  font-size: 13px;
  font-weight: 700;
  box-sizing: border-box;
`;

const SubMenuBarItem = styled.li`
  box-sizing: content-box;
  width: 250px;
  height: 100%;
  border-right: 2px solid rgba(255, 255, 255, 0.4);
  transition: all 0.3s ease;
  color: #fff;

  &:first-child {
    border-left: 2px solid rgba(255, 255, 255, 0.4);
  }
`;

const SubMenuBarItemSpan = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &.active {
    color: var(--primary-c);
    background-color: #fff;
  }
`;


export default function MobileSubVisual({ title, activeMenu, backgroundImage, menuList, setActiveMenu }) {
  const navigate = useNavigate();

  const handleMenuClick = (menu) => {
    if (!setActiveMenu) return;
    setActiveMenu(menu);
  }

  return (
    <TitleSection $backgroundImage={backgroundImage}>
      <TitleSectionInner>
        <TitleH2>{title}</TitleH2>
        <Breadcrumb >
          <HomeIconImg
            src={HomeIcon}
            alt="홈 아이콘"
            onClick={() => navigate("/")}
          />
          <ArrowIconWrapper>
            <img src={Triangle} alt="화살표 아이콘" />
          </ArrowIconWrapper>
          {title}
          <ArrowIconWrapper>
            <img src={Triangle} alt="화살표 아이콘" />
          </ArrowIconWrapper>
          {activeMenu}
        </Breadcrumb>
      </TitleSectionInner>
      <SubMenuBarContainer>
        {menuList.map((menu, index) => (
          <SubMenuBarItem key={index} >
            <SubMenuBarItemSpan className={activeMenu === menu ? "active" : ""} onClick={() => handleMenuClick(menu)}>{menu}</SubMenuBarItemSpan>
          </SubMenuBarItem>
        ))}
      </SubMenuBarContainer>
    </TitleSection>
  )
}
