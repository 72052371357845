import { useCallback, useEffect, useState } from "react";
import { membersState } from "../../../store/atom";
import { useRecoilState } from "recoil";
import {
  createMember,
  deleteMember,
  getMembers,
  updateMemberOrder,
  updateMember,
} from "../../../api/members";
import UpIcon from "../../../assets/icons/up.svg";
import DownIcon from "../../../assets/icons/down.svg";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ResetIcon } from "../../../assets/icons/reset.svg";
import MobileCreateMemberModal from "../../../components/mobile/MobileCreateMemberModal";
import MobileConfirmDeleteModal from "../../../components/mobile/MobileConfirmDeleteModal";

export const Container = styled.section`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  @media (max-width: 440px) {
    flex-wrap: wrap;
  }
`;

export const OrderButtonContainer = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  gap: 10px;

  > span {
    line-height: 24px;
  }
`;

const SelectedMemberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  font-size: 1.4rem;

  > p {
    font-weight: bold;
    color: #2aa4d5;
    min-width: 50px;
  }
`;

const OrderChangeContainer = styled.div`
  display: flex;
  align-items: center;
  gap:5px;
  font-size: 1.4rem;

  > span {
    min-width: 50px;
  }
`;

const OrderButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const OrderButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3C3E4559;
  position: relative;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3C3E45;
  }
`;
const OrderButtonUpImg = styled.img`
  position: absolute;
  top: 6px;
  left: 7px;
  width: 15px;
  height: 15px;
`;
const OrderButtonDownImg = styled.img`
  position: absolute;
  top: 9px;
  left: 7.5px;
  width: 15px;
  height: 15px;
`;

const WriteButton = styled.button`
  display: inline-block;
  width: 100px;;
  height: 40px;
  background-color: #2aa4d5;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 12px;
  transition: background-color 0.3s ease;
  font-size: 1.4rem;

  &:hover {
    background-color: var(--primary-c-hover);
  }
`;

const TableContainer = styled.section`
  width: 100%;
`;

const TableEl = styled.table`
  width: 100%;
  border-top: 3px solid #2aa4d5;
  border-bottom: 2px solid #cfd0d1;
`;

const TableHead = styled.thead`
  background-color: #e9f6fb;
  font-size: 14px;
  font-weight: 800;
  line-height: 15px;
  letter-spacing: -0.63px;
  color: #4e4e4e;
`;

const TableBody = styled.tbody`
  font-size: 13px;
  font-weight: 400;
  color: #4e4e4e;
`;

const TableRowEl = styled.tr`
  display: flex;
  border-bottom: 1px solid #e5e5e5;

  &:last-child {
    border-bottom: none;
  }
`;

const TableBodyRow = styled(TableRowEl)`
  &:hover {
    background-color: rgba(234, 234, 234, 0.7);
  }
`;

const TableHeadItem = styled.th`
  padding-top: 24px;
  padding-bottom: 22px;
`;

const TableHeadItemNo = styled(TableHeadItem)`
 min-width: 30px;
`;

const TableHeadItemPosition = styled(TableHeadItem)`
  width: 70px;
`;

const TableHeadItemName = styled(TableHeadItem)`
  width: 40px;
`;

const TableHeadItemRole = styled(TableHeadItem)`
  width: 70px;

  @media (max-width: 440px) {
    display: none;
  }
`;

const TableHeadItemEmail = styled(TableHeadItem)`
  flex-grow: 1;
`;

const TableHeadItemManage = styled(TableHeadItem)`
  width: 90px;
  justify-content: center;

  @media (max-width: 440px) {
    width: 60px;
  }
`;

const TableBodyItem = styled.td`
  display: flex;
  align-items: center;
  padding: 15px 0;
`;
const TableEmptyMessage = styled(TableRowEl)`
  justify-content: center;
`;

const TableBodyItemNo = styled(TableBodyItem)`
  min-width: 30px;
  justify-content: center;
`;

const TableBodyItemPosition = styled(TableBodyItem)`
  width: 70px;
  text-align: center;
  justify-content: center;
  span {
    cursor: pointer;
  }
`;

const TableBodyItemName = styled(TableBodyItem)`
  width: 40px;
  justify-content: center;
`;

const TableBodyItemRole = styled(TableBodyItem)`
  width: 70px;
  justify-content: center;
  
  @media (max-width: 440px) {
    display: none;
  }
`;

const TableBodyItemEmail = styled(TableBodyItem)`
  justify-content: center;
  flex-grow: 1;

  @media (max-width: 490px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
  }
`;

const TableBodyItemManage = styled(TableBodyItem)`
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (max-width: 440px) {
    width: 60px;
    flex-direction: column;
    align-items: center;
  }
`;

const Button = styled.button`
  height: 30px;
  padding: 0 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  text-align: center;

  @media (max-width: 440px) {
    height: 28px;
  }
`;
const EditButton = styled(Button)`
  background-color: #186fb2;

  &:hover {
    background-color: #186fb2;
  }
`;
const DeleteButton = styled(Button)`
  background-color: #3d3d3d;

  &:hover {
    background-color: #3d3d3d;
  }
`;

const SearchBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f8f8f8;
  border-radius: 15px;
  padding: 20px 10px;
  height: 90px;
  width: 100%;
`;

const ResetButton = styled.button`
  font-size: 14px;
  color: #3C3E45;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 60px;
  height: 30px;
  border-radius: 15px;
  padding: 10px 0;
  transition: all 0.3s ease;
  
  >svg {
    width: 20px;
    height: 20px;
    fill: #3C3E45;
    transition: fill 0.3s ease;
  }

  &:hover {
    font-weight: 700;

  }
`;

const SearchBox = styled.form`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  position: relative;
  align-self: flex-end;
  height: 100%;
  width: 100%;

  >input{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 1px solid var(--border-c);
    padding-left: 15px;
    padding-right: 60px;
    background-color: #fff;
    font-size: 14px;
  }
`;

const SearchButton = styled.button` 
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height:35px;
  background-color: var(--primary-c);
  border-radius: 15px;
  padding: 7px 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--primary-c-hover);
  }

  >svg {
    width: 100%;
    height: 100%;
  }
`;

export function Members() {
  const [members, setMembers] = useRecoilState(membersState);
  const [selectedMember, setSelectedMember] = useState(-1);
  const [editingMemberId, setEditingMemberId] = useState(-1);
  const [openCreateMemberModal, setOpenCreateMemberModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const handleWriteButtonClick = () => {
    setEditingMemberId(-1);
    setOpenCreateMemberModal(true);
  }

  const fetchMembers = useCallback(async (name = "") => {
    const data = await getMembers();
    const filteredMembers = name.trim() === "" ? data.members : data.members.filter(member => member.name.includes(name));
    setMembers(filteredMembers);
  }, [setMembers]);

  const handleOrderUp = useCallback(async () => {
    if (selectedMember === -1) return;
    const index = members.findIndex((member) => member.id === selectedMember);
    await updateMemberOrder(selectedMember, index - 1);
    fetchMembers();
  }, [selectedMember, members, fetchMembers]);

  const handleOrderDown = useCallback(async () => {
    if (selectedMember === -1) return;
    const index = members.findIndex((member) => member.id === selectedMember);
    await updateMemberOrder(selectedMember, index + 1);
    fetchMembers();
  }, [selectedMember, members, fetchMembers]);

  const handleCreateMember = useCallback(
    async (member) => {
      await createMember(member);
      fetchMembers();
      setOpenCreateMemberModal(false);
    },
    [fetchMembers]
  );

  const handleDeleteMember = useCallback(
    async (id) => {
      setSelectedMember(id);
      setIsOpenDeleteModal(true);
      // fetchMembers();
    },
    [fetchMembers]
  );

  const handleConfirmDelete = async (isConfirmed) => {
    setIsOpenDeleteModal(false);
    if (isConfirmed) {
      try {
        await deleteMember(selectedMember);
        fetchMembers();
      } catch (error) {
        console.error("구성원 삭제 실패:", error);
      }
    }
  };

  const handleEditMember = (id) => {
    setEditingMemberId(id);
    setOpenCreateMemberModal(true);
  };

  const handleUpdateMember = useCallback(
    async (member) => {
      await updateMember(member.id, member);
      fetchMembers();
      setOpenCreateMemberModal(false);
      setEditingMemberId(-1);
    },
    [fetchMembers]
  );

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  // 이름 검색
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    if (name.trim() === "") {
      fetchMembers();
    } else {
      fetchMembers(name);
    }
  }

  const handleResetSearch = () => {
    fetchMembers(); // 모든 구성원 다시 불러오기
  };

  return (
    <Container>
      <ButtonContainer>
        <OrderButtonContainer>
          <SelectedMemberContainer>
            선택 항목 :
            {(() => {
              const selectedMemberName = members.find(member => member.id === selectedMember)?.name || "";
              return selectedMemberName ? (
                <p>
                  {selectedMemberName}
                </p>
              ) : <p></p>;
            })()}
          </SelectedMemberContainer>
          <OrderChangeContainer>
            <span>순서 변경</span>
            <OrderButtonWrapper>
              <OrderButton onClick={handleOrderUp}>
                <OrderButtonUpImg src={UpIcon} alt="위" />
              </OrderButton>
              <OrderButton onClick={handleOrderDown}>
                <OrderButtonDownImg src={DownIcon} alt="아래" />
              </OrderButton>
            </OrderButtonWrapper>
          </OrderChangeContainer>
        </OrderButtonContainer>
        <WriteButton onClick={handleWriteButtonClick}>
          구성원 추가
        </WriteButton>

      </ButtonContainer>
      <TableContainer>
        <TableEl>
          <TableHead>
            <TableRowEl>
              <TableHeadItemNo>No.</TableHeadItemNo>
              <TableHeadItemPosition>근무지</TableHeadItemPosition>
              <TableHeadItemName>이름</TableHeadItemName>
              <TableHeadItemRole>직책</TableHeadItemRole>
              <TableHeadItemEmail>이메일</TableHeadItemEmail>
              <TableHeadItemManage>관리</TableHeadItemManage>
            </TableRowEl>
          </TableHead>
          <TableBody>
            {members.length === 0 ? (
              <TableEmptyMessage>
                <TableBodyItem>구성원이 존재하지 않습니다.</TableBodyItem>
              </TableEmptyMessage>
            ) : (
              members.map((member, index) => (
                <MemberRow
                  key={member.id}
                  no={index + 1}
                  {...member}
                  onEdit={handleEditMember}
                  onDelete={handleDeleteMember}
                  selectedMember={selectedMember}
                  setSelectedMember={setSelectedMember}
                />
              )))}
          </TableBody>
        </TableEl>
      </TableContainer>
      <SearchBoxContainer>
        <ResetButton onClick={handleResetSearch}>
          <ResetIcon />
          초기화
        </ResetButton>
        <SearchBox onSubmit={handleSearchSubmit} >
          <input type="text" placeholder="이름 검색" name="name" />
          <SearchButton type="submit">
            <SearchIcon />
          </SearchButton>
        </SearchBox>
      </SearchBoxContainer>
      {
        openCreateMemberModal && (
          <MobileCreateMemberModal
            onSave={
              editingMemberId === -1 ? handleCreateMember : handleUpdateMember
            }
            onClose={() => setOpenCreateMemberModal(false)}
            memberId={editingMemberId}
            handleEdit={handleEditMember}
          />
        )
      }
      {isOpenDeleteModal && (
        <MobileConfirmDeleteModal onConfirm={handleConfirmDelete} />
      )}

    </Container>
  );
}

function MemberRow({
  onEdit,
  onDelete,
  selectedMember,
  setSelectedMember,
  ...props
}) {
  const isSelected = selectedMember === props.id;

  const handleClick = () => {
    if (isSelected) {
      setSelectedMember(-1);
    } else {
      setSelectedMember(props.id);
    }
  };

  return (
    <TableBodyRow
      onClick={handleClick}
      style={{
        ...(isSelected && { backgroundColor: "lightgray" }),
        cursor: "pointer",
      }}
    >
      <TableBodyItemNo>{props.no}</TableBodyItemNo>
      <TableBodyItemPosition>{props.workplace}</TableBodyItemPosition>
      <TableBodyItemName>{props.name}</TableBodyItemName>
      <TableBodyItemRole>{props.role}</TableBodyItemRole>
      <TableBodyItemEmail>{props.email}</TableBodyItemEmail>
      <TableBodyItemManage>
        <EditButton onClick={(e) => {
          e.stopPropagation();
          onEdit(props.id);
        }}>수정</EditButton>
        <DeleteButton onClick={(e) => {
          e.stopPropagation();
          onDelete(props.id);
        }}>삭제</DeleteButton>
      </TableBodyItemManage>
    </TableBodyRow>
  );
}


