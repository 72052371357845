import { Link, useLocation } from "react-router-dom";
import LogoTransparent from "../../assets/images/logo_transparent.png";
import LogoColor from "../../assets/images/logo_default.png";
import styled from "styled-components";
import { useEffect, useState } from "react";

const HeaderContainer = styled.header`
  position: ${({ $isMain }) => ($isMain ? "absolute" : "relative")};
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: ${({ $isOpen }) =>
    $isOpen ? "background-color 0s" : "background-color 0.3s ease 0.4s"};
  background-color: ${({ $isMain, $isOpen }) =>
    $isOpen ? "white" : $isMain ? "transparent" : "rgb(0, 0, 0, 0.05)"};
`;

const HeaderInner = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
  padding: 25px;
`;

const Logo = styled.h1`
  display: flex;
  align-items: center;
`;

const HamburgerButton = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 80;

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${({ $isMain, $isOpen }) =>
    $isOpen ? "#000" : "#fff"};

    transition: all 0.3s ease;
    transform-origin: center;
    transition: ${({ $isOpen }) =>
    $isOpen
      ? "all 0.3s ease"
      : "all 0.3s ease, background-color 0.3s ease 0.4s"};

    &:nth-child(1) {
      top: 50%;
      transform: ${({ $isOpen }) =>
    $isOpen ? "rotate(45deg)" : "translateY(-8px)"};
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      opacity: ${({ $isOpen }) => ($isOpen ? "0" : "1")};
    }

    &:nth-child(3) {
      top: 50%;
      transform: ${({ $isOpen }) =>
    $isOpen ? "rotate(-45deg)" : "translateY(6px)"};
    }
  }
`;

const Nav = styled.nav`
  width: ${({ $isOpen }) => ($isOpen ? "100%" : "0")};
  height: 100vh;
  transition:  0.3s linear 0s;
  background-color: transparent;
  position: absolute;
  top: 98%;
  left: 0;
  overflow: hidden;
`;

const LinkText = styled.span`
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0 0 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
`;

const NavItem = styled.li`
  display: flex;
  width: 100%;
  cursor: pointer;
  padding: 20px 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  transition: color 0.3s ease;
  
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  transition: all 0.3s ease-in-out;
  height: 100%;
  width: 100%;
  color: #303030;
  `;

const NavSubMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  transition:all 0.3s ease;
`;

const AboutNavSubMenuList = styled(NavSubMenuList)`
  height: ${({ $isOpen }) => ($isOpen ? '120px' : '0')};
`;
const MembersNavSubMenuList = styled(NavSubMenuList)`
  height: ${({ $isOpen }) => ($isOpen ? '150px' : '0')};
`;
const ServicesNavSubMenuList = styled(NavSubMenuList)`
  height: ${({ $isOpen }) => ($isOpen ? '120px' : '0')};
`;
const ConsultingNavSubMenuList = styled(NavSubMenuList)`
  height: ${({ $isOpen }) => ($isOpen ? '30px' : '0')};
`;

const NavSubMenuLink = styled(Link)`
  width: 100%;
  line-height: 20px;
  padding: 5px 35px;
  transition: color 0.3s ease;
  &:hover {
    color: #2aa4d5;
  }
`;

const LogoImage = styled.img`
  width: 150px;
  transition: opacity 0.3s ease;
  opacity: 1;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 30px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${({ $switching }) => ($switching ? 1 : 0)};
    transition: opacity 0.3s ease
      ${({ $switching }) => ($switching ? "0s" : "0.3s")};
  }
`;

const MobileHeader = () => {
  const isMain = useLocation().pathname === "/";
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    company: false,
    members: false,
    services: false,
    consulting: false,
  });

  const pathname = useLocation().pathname;

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  const toggleNav = () => setIsOpen(!isOpen);
  const toggleSubMenu = (menu) => {
    setIsSubMenuOpen((prev) => {
      const isOpen = prev[menu];
      return {
        company: false,
        members: false,
        services: false,
        consulting: false,
        [menu]: !isOpen,
      };
    });
  };

  return (
    <HeaderContainer $isMain={isMain} $isOpen={isOpen}>
      <HeaderInner>
        <Logo>
          <Link to="/">
            <LogoWrapper $switching={!isMain || isOpen}>
              <LogoImage
                src={LogoTransparent}
                alt="회계법인 새시대"
                style={{ opacity: !isOpen ? 1 : 0 }}
              />
              <LogoImage
                src={LogoColor}
                alt="회계법인 새시대"
                style={{ opacity: isOpen ? 1 : 0 }}
              />
            </LogoWrapper>
            <LinkText>회계법인 새시대</LinkText>
          </Link>
        </Logo>
        <HamburgerButton
          $isOpen={isOpen}
          $isMain={isMain}
          onClick={toggleNav}
          aria-label="메뉴 열기"
        >
          <span></span>
          <span></span>
          <span></span>
        </HamburgerButton>
      </HeaderInner>
      <Nav $isOpen={isOpen}>
        <NavList $isMain={isMain} $isOpen={isOpen}>
          <NavItem onClick={() => toggleSubMenu('company')}>
            회사소개
          </NavItem>
          <AboutNavSubMenuList $isOpen={isSubMenuOpen.company}>
            <NavSubMenuLink to="/about?menu=대표이사 인사말" onClick={() => setIsOpen(false)}>대표이사 인사말</NavSubMenuLink>
            <NavSubMenuLink to="/about?menu=연혁" onClick={() => setIsOpen(false)}>연혁</NavSubMenuLink>
            <NavSubMenuLink to="/about?menu=조직도" onClick={() => setIsOpen(false)}>조직도</NavSubMenuLink>
            <NavSubMenuLink to="/about?menu=오시는 길" onClick={() => setIsOpen(false)}>오시는 길</NavSubMenuLink>
          </AboutNavSubMenuList>
          <NavItem onClick={() => toggleSubMenu('members')}>
            구성원
          </NavItem>
          <MembersNavSubMenuList $isOpen={isSubMenuOpen.members}>
            <NavSubMenuLink to="/members?menu=전체보기" onClick={() => setIsOpen(false)}>전체보기</NavSubMenuLink>
            <NavSubMenuLink to="/members?menu=본점" onClick={() => setIsOpen(false)}>본점</NavSubMenuLink>
            <NavSubMenuLink to="/members?menu=구로분실" onClick={() => setIsOpen(false)}>구로분실</NavSubMenuLink>
            <NavSubMenuLink to="/members?menu=강남분실" onClick={() => setIsOpen(false)}>강남분실</NavSubMenuLink>
            <NavSubMenuLink to="/members?menu=광주지점" onClick={() => setIsOpen(false)}>광주지점</NavSubMenuLink>
          </MembersNavSubMenuList>
          <NavItem onClick={() => toggleSubMenu('services')}>
            서비스
          </NavItem>
          <ServicesNavSubMenuList $isOpen={isSubMenuOpen.services}>
            <NavSubMenuLink to="/services?menu=회계감사" onClick={() => setIsOpen(false)}>회계감사</NavSubMenuLink>
            <NavSubMenuLink to="/services?menu=세무자문" onClick={() => setIsOpen(false)}>세무자문</NavSubMenuLink>
            <NavSubMenuLink to="/services?menu=재무자문" onClick={() => setIsOpen(false)}>재무자문</NavSubMenuLink>
            <NavSubMenuLink to="/services?menu=경영자문" onClick={() => setIsOpen(false)}>경영자문</NavSubMenuLink>
          </ServicesNavSubMenuList>
          <NavItem onClick={() => toggleSubMenu('consulting')}>
            상담문의
          </NavItem>
          <ConsultingNavSubMenuList $isOpen={isSubMenuOpen.consulting}>
            <NavSubMenuLink to="/consulting" onClick={() => setIsOpen(false)}>온라인 상담 신청</NavSubMenuLink>
          </ConsultingNavSubMenuList>
        </NavList>
      </Nav>
    </HeaderContainer >
  );
};

export default MobileHeader;
