import { Login } from "./login";
import { Members } from "./members";
import { Consulting } from "./consulting";
import { Setting } from "./setting";
import { DetailConsulting } from "./detailConsulting";
const Admin = {
  Login,
  Members,
  Consulting,
  DetailConsulting,
  Setting,
};

export default Admin;
