import { Outlet, useLocation } from "react-router-dom";

import "../styles/layout.css";
import Header from "./Header";
import Footer from "./Footer";
import { useRef, useState } from "react";

export default function Layout() {

  const isMain = useLocation().pathname === "/";

  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main >
      {!isMain && <Footer />}
    </ >
  );
}
