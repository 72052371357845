import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import About from "./pages/about";
import { Members } from "./pages/members";
import { Services } from "./pages/services";
import { Consulting } from "./pages/consulting";
import AdminLayout from "./components/AdminLayout";
import ScrollToTop from "./components/ScrollToTop";
import Admin from "./pages/admin";
import AdminMemberLayout from "./components/AdminMemberLayout";
import AdminConsultLayout from "./components/AdminConsultLayout";
import useViewport from "./hooks/useViewport";
import MobileLayout from "./components/mobile/MobileLayout";
import Mobile from "./pages/mobile";
import MobileAdminLayout from "./components/mobile/MobileAdminLayout";
import MobileAdminMemberLayout from "./components/mobile/MobileAdminMemberLayout";
import MobileAdminConsultLayout from "./components/mobile/MobileAdminConsultLayout";
import MobileAdmin from "./pages/admin/mobile";
import NewHome from "./pages/newHome";

function App() {
  const { isMobile } = useViewport();

  console.log(isMobile);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={isMobile ? <MobileLayout /> : <Layout />}>
          <Route index element={isMobile ? <Mobile.MobileNewHome /> : <NewHome />} />
          <Route path="/about" element={isMobile ? <Mobile.About /> : <About />} />
          <Route path="/members" element={isMobile ? <Mobile.Members /> : <Members />} />
          <Route path="/services" element={isMobile ? <Mobile.Services /> : <Services />} />
          <Route path="/consulting" element={isMobile ? <Mobile.Consulting /> : <Consulting />} />
        </Route>

        <Route path="/admin" element={isMobile ? <MobileAdminLayout /> : <AdminLayout />}>
          <Route index element={<Navigate to="members" replace />} />
          <Route path="members" element={isMobile ? <MobileAdminMemberLayout /> : <AdminMemberLayout />}>
            <Route index element={isMobile ? <MobileAdmin.Members /> : <Admin.Members />} />
          </Route>
          <Route path="consulting" element={isMobile ? <MobileAdminConsultLayout /> : <AdminConsultLayout />}>
            <Route index element={isMobile ? <MobileAdmin.Consulting /> : <Admin.Consulting />} />
            <Route path=":id" element={isMobile ? <MobileAdmin.DetailConsulting /> : <Admin.DetailConsulting />} />
          </Route>
          <Route path="setting" element={isMobile ? <MobileAdmin.Setting /> : <Admin.Setting />} />
        </Route>
        <Route path="/admin/login" element={isMobile ? <MobileAdmin.Login /> : <Admin.Login />} />
        <Route path="*" element={<div style={{ fontSize: "2rem" }}>Page not found</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
