import { Link, Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { isLoginState, tokenState, userIdState } from "../../store/atom";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useCallback, useEffect, useState } from "react";
import { getUsers } from "../../api/users";
import styled from "styled-components";
import LogoWhite from "../../assets/images/logo_transparent.png";
import UserIcon from "../../assets/icons/admin_user.png";
import UnlockIcon from "../../assets/icons/unlock.png";

const Container = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
`;

const Sidebar = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #00a7db;
  z-index: 1000;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const Logo = styled.img`
  display: block;
  width: 180px;
  height: 36px;
  cursor: pointer;
  position: relative;
`;

const MenuOpenButton = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 80;
  color: #fff;

  span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background: #fff;
    transition: all 0.3s ease;
    transform-origin: center;

    &:nth-child(1) {
      top: 50%;
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(45deg)" : "translateY(-8px)")};
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      opacity: ${({ $isOpen }) => ($isOpen ? "0" : "1")};
    }

    &:nth-child(3) {
      top: 50%;
      transform: ${({ $isOpen }) => ($isOpen ? "rotate(-45deg)" : "translateY(6px)")};
    }
  }
`;


const MenuContainer = styled.div`
  transition: all 0.5s ease;
  height: ${({ isOpen }) => (isOpen ? '370px' : '0')};
  padding-bottom: ${({ isOpen }) => (isOpen ? '20px' : '0')};
  overflow: hidden;
`;

const UserIconImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 50px;
  height: 50px;
  margin-top: 20px;

`;
const Greeting = styled.h1`
  font-size: 18px;
  color: #fff;
  text-align: center;
  margin-bottom: 15px;

  span {
    font-weight: 700;
  }
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-self: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  transition: background-color 0.3s ease;

  img {
    width: 12px;
    height: 15px;
  }
  

  &:hover {
    background-color: #e0e0e0;
  }
`;

const MenuList = styled.ul`
  margin-top: 20px;
  font-size: 24px;
  line-height: 30px;
`;

const MenuItem = styled.li`
  background-color: ${({ $isActive }) => ($isActive ? "#151531" : "#00a7db")};
  font-size: 20px;
  transition: font-weight 0.3s ease;

  a {
    width: 100%;
    height: 100%;
    display: block;
    padding: 15px 0;
    padding-left: 20px;
    color: #fff;
  }
  &:hover {
    background-color: ${({ $isActive }) => ($isActive ? "#151531" : "#0087b3")};
  }
`;

export const MainContent = styled.section`
  padding-top: 68px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;


export default function MobileAdminLayout() {
  const pathname = useLocation().pathname;
  const isLogin = useRecoilValue(isLoginState);
  const userId = useRecoilValue(userIdState);
  const [username, setUsername] = useState("");
  const isActive = useCallback(
    (path) => pathname.startsWith(path),
    [pathname]
  );

  const resetToken = useResetRecoilState(tokenState);
  const resetUserId = useResetRecoilState(userIdState);

  const navigate = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    if (isMenuOpen) {
      setTimeout(() => setIsSidebarOpen(false), 300);
    } else {
      setIsSidebarOpen(true);
    }
  };

  useEffect(() => {
    if (isLogin) {
      getUsers().then((data) => {
        const { users } = data;
        const curUser = users.find((user) => user.id === userId);
        setUsername(curUser.username);
      });
    }
  }, [isLogin, userId]);

  useEffect(() => {
    if (!isLogin) {
      setIsSidebarOpen(true);
    }
  }, [isLogin]);

  if (!isLogin) {
    resetToken();
    resetUserId();
    return <Navigate to="/admin/login" replace />;
  }

  const handleLogout = () => {
    resetToken();
    resetUserId();
    navigate("/admin/login");
  };

  return (
    <Container>
      <Sidebar isOpen={isSidebarOpen}>
        <SidebarHeader>
          <Logo src={LogoWhite} alt="로고" onClick={() => navigate("/")} />
          <MenuOpenButton $isOpen={isMenuOpen} onClick={toggleMenu} aria-label="메뉴 열기">
            <span></span>
            <span></span>
            <span></span>
          </MenuOpenButton>
        </SidebarHeader>

        <MenuContainer isOpen={isMenuOpen}>
          <UserIconImg src={UserIcon} alt="유저 아이콘" />
          <Greeting>
            <span>
              {username}
            </span>
            님 환영합니다.</Greeting>
          <LogoutButton onClick={handleLogout}>
            <img src={UnlockIcon} alt="잠금 아이콘" />
            <span>로그아웃</span>
          </LogoutButton>
          <MenuList>
            <MenuItem $isActive={isActive("/admin/members")} onClick={toggleMenu}>
              <Link to="/admin/members">구성원</Link>
            </MenuItem>
            <MenuItem $isActive={isActive("/admin/consulting")} onClick={toggleMenu}>
              <Link to="/admin/consulting">상담문의</Link>
            </MenuItem>
            <MenuItem $isActive={isActive("/admin/setting")} onClick={toggleMenu}>
              <Link to="/admin/setting">설정</Link>
            </MenuItem>
          </MenuList>
        </MenuContainer>

      </Sidebar>
      <MainContent>
        <Outlet />
      </MainContent>
    </Container >
  );
}
