import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { BASE_URL } from "../api/client";
import { getMembers } from "../api/members";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import avatar from "../assets/images/avatar_3.png";
import BackgroundImage from "../assets/images/visual_members.jpg";
import MemberDetailModal from "../components/MemberDetailModal";
import SubTitle from "../components/SubTitle";
import SubVisual from "../components/SubVisual";

const MainContent = styled.section`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1400px;
 

  @media (max-width: 1840px) {
    padding: 0 20px;
  }
`;

const MembersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 140px;
`;

const MembersContainerTitleBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding: 20px;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
  transform: translateX(0);
}
}
`;

const SearchBox = styled.form`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 

  >input{
    width: 500px;
    height: 100%;
    border: 1px solid var(--border-c);
    padding-left: 25px;
    padding-right: 110px;
    background-color: #fff;
    font-size: 16px;
  }
`;

const SearchButton = styled.button` 
  width: 100px;
  height: 100%;
  background-color: var(--primary-c);
  padding: 10px 5px;

  >svg {
    width: 100%;
    height: 100%;
  }
`;

const MemberListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 90px 50px;
  animation: fadeInDown 1.5s forwards;

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  to {
    opacity: 1;
  transform: translateX(0);
}
}

  @media (max-width: 1200px) {
    gap: 40px 20p
  }

  @media (max-width:960px) {
    grid-template-columns: repeat(3, 1fr);
  }
  `;

const MemberItemWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

const MemberItemImageBox = styled.div`
  position: relative;
 `;

const MemberItemImageWrapper = styled.div`
  padding: 57.35% 0;
  
  img{
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); 
    width: 100%;
    height: 100%;
    max-width: 270px;
    overflow: hidden
  }
  `

const MemberDetailButton = styled.button`
  position: absolute;
  bottom: -15px;
  right: 0;
  background-color: #bfbfbf;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  z-index: 10;
  padding: 28px 26px 27px 29px;
  transition: all 0.3s ease;

  @media (max-width: 460px) {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 375px) {
    padding: 10px 8px 9px 11px;
    width: 30px;
    height: 30px;
  }

  >svg {
    width: 100%;
    height: 100%;
  }
`;

const MemberInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-left: 50px;
`;

const MemberInfoItem = styled.div`
  display: flex;
  align-items: flex-end;  
  gap: 15px;
  font-weight: 700;
  width: 100%;
  `;

const MemberInfoItemRole = styled.p`
  font-size: 1.8rem;
  color: var(--primary-c);
`;

const MemberInfoItemName = styled.h4`
  font-size:2.4rem;
`;

export function Members() {
  const [isLoading, setIsLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [activeMenu, setActiveMenu] = useState("전체보기");

  const navigate = useNavigate();

  const menuList = useMemo(() => ["전체보기", "본점", "구로분실", "강남분실", "광주지점"], []);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleMenuClick = (menu) => {
    setSearchParams({ menu });
    setActiveMenu(menu);
  }

  useEffect(() => {
    const menu = searchParams.get("menu");

    if (!menu || !menuList.includes(menu)) {
      navigate("/about?menu=전체보기", { replace: true });
    }

    if (menu && menuList.includes(menu)) {
      setActiveMenu(menu);
    }
  }, [searchParams, menuList, navigate]);


  const fetchMembers = useCallback(async (name) => {
    setIsLoading(true);
    const data = await getMembers(activeMenu, name);
    const { members } = data;
    const serialMembers = members.map((member, index) => {
      const { createdAt, ...rest } = member;
      return {
        ...rest,
      };
    });
    setMembers(serialMembers);
    setIsLoading(false);
  }, [setMembers, activeMenu]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    if (name.trim() === "") {
      fetchMembers();
    } else {
      fetchMembers(name);
    }
  }

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers, activeMenu, navigate]);


  return (
    <div>
      <SubVisual title="구성원" menuList={menuList} activeMenu={activeMenu} backgroundImage={BackgroundImage} setActiveMenu={handleMenuClick} />
      <MainContent>
        {!isLoading && (
          <MembersContainer>
            <MembersContainerTitleBox>
              <SubTitle title={activeMenu} />
              <SearchBoxWrapper>
                <SearchBox onSubmit={handleSearchSubmit}>
                  <input type="text" placeholder="이름 검색" name="name" />
                  <SearchButton type="submit">
                    <SearchIcon />
                  </SearchButton>
                </SearchBox>
              </SearchBoxWrapper>
            </MembersContainerTitleBox>
            <MemberListContainer>
              {members &&
                members.map((member, index) => (
                  <MemberItemWrapper
                    key={member.id}
                    no={index + 1}
                    {...member}
                  >
                    <MemberItemImageBox>
                      <MemberItemImageWrapper>
                        {member.profile ? (
                          <img
                            src={`${BASE_URL}/image/${member.profile}`}
                            alt={member.name}
                          />
                        ) : (
                          <img src={avatar} alt={member.name} />
                        )}
                      </MemberItemImageWrapper>
                      <MemberDetailButton

                        onClick={() => {
                          setSelectedMemberId(member.id);
                          setIsModalOpen(true);
                        }}
                      >
                        <SearchIcon />
                      </MemberDetailButton>
                    </MemberItemImageBox>
                    <MemberInfoContainer>
                      <MemberInfoItem>
                        <MemberInfoItemRole>{member.role}</MemberInfoItemRole>
                        <MemberInfoItemName>{member.name}</MemberInfoItemName>
                      </MemberInfoItem>
                    </MemberInfoContainer>
                  </MemberItemWrapper>
                ))}
              {isModalOpen && (
                <MemberDetailModal
                  onClose={() => {
                    setIsModalOpen(false);
                    setSelectedMemberId(null);
                  }}
                  memberId={selectedMemberId}
                />
              )}
            </MemberListContainer>
          </MembersContainer>
        )}
      </MainContent>
    </div>
  );
}