import { useCallback, useEffect, useRef, useState } from "react";
import { getMember } from "../../api/members";
import { BASE_URL } from "../../api/client";
import styled from "styled-components";
import { ReactComponent as NoImageIcon } from "../../assets/icons/add_photo_alternate.svg";

const ModalContainer = styled.div`
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
  width: 90%;
  height: auto;
  max-height: 80vh; /* vh 단위를 사용하여 스크롤 디자인을 개선 */
  background-color: white;
  border-radius: 20px 10px 10px 20px;
  padding: 20px;
  overflow-y: auto; /* 스크롤을 표시 */
  font-size: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* 그림자 추가로 디자인 개선 */
  position: relative;

  /* 스크롤 디자인 변경 */
  &::-webkit-scrollbar {
    width: 8px; /* 스크롤바 너비 */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* 스��롤바 색상 */
    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* 스크롤바 호버 색상 */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* 스크롤바 배경 색상 */
    border-radius: 10px; /* 스크롤바 트랙 모서리 둥글게 */
  }
`;


const ModalTitle = styled.h2`
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 10px;
  border-bottom: 2px solid #186FB2;
  color: #186FB2;
`;

const MemberInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  width: 100%;
`;

const MemberProfileWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  >h3 {
    font-weight: 700;
  }
`;

const ProfileImageWrapper = styled.div`
  width: 130px;
  height: 155px;
  border: 1px solid #C2C3C4;
  border-radius: 10px;
  text-align: center;
  font-size: 13px;
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ProfileImage = styled.img`
  display: ${({ $hasImage }) => $hasImage ? "block" : "none"};
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProfileImageArea = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 5px;

  >svg {
    width: 60px;
    height: 60px;
  }
`;

const SelectedFileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SelectedFileLabel = styled.label`
  height: 36px;
  line-height: 36px;
  min-width: 80px;
  border-radius: 5px;
  background-color: #3D3D3D;
  color: white;
  padding: 0 10px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #2D2D2D;
  }
`;

const SelectedFileInput = styled.input`
  color: #9B9B9B;
  &::file-selector-button {
    display: none;
  }
`;

const MemberInfoContainer = styled.div`
  display: flex;
  flex-direction: column; 
  gap: 20px;
  box-sizing: border-box;
`;

const MemberInfoItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 475px) {
    flex-direction: column;
  }
`;

const MemberInfoItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
`;

const MemberInfoItemLabel = styled.label`
  padding-top: 10px;
  width: 45px;
  font-weight: 700;
  color: #303030;
`;

const MemberRoleTelItemLabel = styled.label`
  width: 40px;
  font-weight: 700;
  color: #303030;
  padding-top: 5px;

  @media (max-width: 475px) {
    width: 45px;
  }
`;

const MemberTelItemSelect = styled.select`
  flex: 1;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #C2C3C4;
  padding: 0 8px;
  line-height: 20px;
  color: #303030;
  font-size: 14px;
`;


const MemberInfoInput = styled.input`
  flex: 1;
  height: 40px;
  width: 130px;
  border-radius: 8px;
  border: 1px solid #C2C3C4;
  padding: 0 10px;
  color: #303030;
  font-size: 14px;

  &::placeholder {
    color: #9B9B9B;
  }
`;

const MemberInfoTextarea = styled.textarea`
  flex: 1;
  height: 80px;
  border-radius: 8px;
  border: 1px solid #C2C3C4;
  padding: 12px 10px;
  line-height: 1.4;
  font-size: 14px;

  &::placeholder {
    color: #9B9B9B;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  color:#fff;
  transition: background-color 0.3s ease;
  margin-top: 10px;
`;

const DeleteButton = styled(Button)`
background-color: #3d3d3d;
  &:hover {
  background-color: #2d2d2d;
}
`;

const CancelButton = styled(Button)`
background-color: #00a7db;
  &:hover {
  background-color: #0087b3;
}
`;

export default function MobileCreateMemberModal({ onSave, onClose, memberId }) {
  const [file, setFile] = useState(null);
  const [profile, setProfile] = useState(undefined);
  const [hasImage, setHasImage] = useState(false);
  const imgRef = useRef(null);
  const fileRef = useRef(null);
  const formRef = useRef(null);

  const isEditing = memberId !== -1;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const { profile: _, ...member } = Object.fromEntries(formData);
    await onSave({
      ...member,
      profile: profile,
      id: isEditing ? memberId : undefined,
    });
  };

  const handleProfileChange = async (e) => {
    const _file = e.target.files[0];
    console.log(_file);
    if (!_file && file && fileRef.current) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileRef.current.files = dataTransfer.files;
      return;
    }
    setFile(_file);
    const profile = _file?.name ? await convertFileToBase64(_file) : "";
    console.log("profile", profile);
    if (profile) setHasImage(true);
    const img = imgRef.current;
    if (img) img.src = profile;
    setProfile(profile);
  };

  const fetchMember = useCallback(async () => {
    const data = await getMember(memberId);
    const { profile, ...member } = data.member;

    if (profile) {
      setHasImage(true);
      const img = imgRef.current;
      if (img) img.src = `${BASE_URL}/image/${profile} `;
    }

    Object.entries(member).forEach(([key, value]) => {
      const input = formRef.current.querySelector(`input[name="${key}"], textarea[name="${key}"], select[name="${key}"]`);
      if (input) input.value = value;
    });
  }, [memberId]);

  useEffect(() => {
    if (isEditing) {
      console.log("fetchMember", memberId);
      fetchMember();
    }
    // 모달이 열릴 때 바깥 스크롤 비활성화
    document.body.style.overflow = 'hidden';

    // 컴포넌트 언마운트 시 바깥 스크롤 복원
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isEditing, fetchMember]);

  async function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  }

  return (
    <ModalContainer>
      <ModalContent>
        <ModalTitle>{isEditing ? "구성원 수정" : "구성원 추가"}</ModalTitle>
        <MemberInfoForm ref={formRef} onSubmit={handleSubmit}>
          <MemberProfileWrapper>
            <h3>프로필 이미지</h3>
            <ProfileImageWrapper htmlFor="file">
              <ProfileImage
                ref={imgRef}
                alt="profile"
                $hasImage={hasImage}
              />
              {!hasImage &&
                <ProfileImageArea htmlFor="file">
                  <NoImageIcon />
                  프로필 이미지를 추가해주세요.
                </ProfileImageArea>
              }
            </ProfileImageWrapper>
            <SelectedFileWrapper>
              <SelectedFileLabel htmlFor="file">
                파일 선택
              </SelectedFileLabel>
              <SelectedFileInput ref={fileRef} type="file" id="file" name="profile" onChange={handleProfileChange} value={file?.name} />
            </SelectedFileWrapper>

          </MemberProfileWrapper>
          <MemberInfoContainer>
            <MemberInfoItemContainer>
              <MemberInfoItemWrapper>
                <MemberInfoItemLabel>이름</MemberInfoItemLabel><MemberInfoInput type="text" name="name" required />
              </MemberInfoItemWrapper>
              <MemberInfoItemWrapper>
                <MemberRoleTelItemLabel>직급/직책</MemberRoleTelItemLabel><MemberInfoInput type="text" name="role" required />
              </MemberInfoItemWrapper>
            </MemberInfoItemContainer>
            <MemberInfoItemContainer>
              <MemberInfoItemWrapper>
                <MemberInfoItemLabel>근무지</MemberInfoItemLabel>
                <MemberTelItemSelect name="workplace">
                  <option value="본점">본점</option>
                  <option value="구로분실">구로분실</option>
                  <option value="강남분실">강남분실</option>
                  <option value="광주지점">광주지점</option>
                </MemberTelItemSelect>
              </MemberInfoItemWrapper>
              <MemberInfoItemWrapper>
                <MemberRoleTelItemLabel>전화번호</MemberRoleTelItemLabel><MemberInfoInput type="tel" name="phone" required />
              </MemberInfoItemWrapper>
            </MemberInfoItemContainer>
            <MemberInfoItemWrapper>
              <MemberInfoItemLabel>이메일</MemberInfoItemLabel><MemberInfoInput type="email" name="email" required />
            </MemberInfoItemWrapper>
            <MemberInfoItemWrapper>
              <MemberInfoItemLabel>서비스</MemberInfoItemLabel><MemberInfoTextarea type="text" name="service" placeholder="서비스 내용을 입력해주세요." />
            </MemberInfoItemWrapper>
            <MemberInfoItemWrapper>
              <MemberInfoItemLabel>학력</MemberInfoItemLabel><MemberInfoTextarea type="text" name="education" placeholder="학력 내용을 입력해주세요." />
            </MemberInfoItemWrapper>
            <MemberInfoItemWrapper>
              <MemberInfoItemLabel>경력</MemberInfoItemLabel><MemberInfoTextarea type="text" name="career" placeholder="경력 내용을 입력해주세요." />
            </MemberInfoItemWrapper>
            <ButtonContainer>
              <CancelButton type="submit">저장</CancelButton>
              <DeleteButton type="button" onClick={onClose}>
                닫기
              </DeleteButton>
            </ButtonContainer>
          </MemberInfoContainer>
        </MemberInfoForm>
      </ModalContent>
    </ModalContainer>
  );
}