import { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, Autoplay, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import newVisual01 from "../../assets/images/mobile_new_visual-1.jpg"
import newVisual02 from "../../assets/images/mobile_new_visual-2.jpg"
import newVisual03 from "../../assets/images/mobile_new_visual-3.jpg"
import newVisual04 from "../../assets/images/mobile_new_visual-4.jpg"
import prevIcon from '../../assets/icons/prev.png';
import nextIcon from '../../assets/icons/next.png';

const VisualSection = styled.section`
  width: 100%;
  height: 50vh;
  position: relative;
  
  @media (min-width: 768px) {
    height: 100vh;
  }


  .swiper {
  width: 100%;
  height: 100%;
  position: relative;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
  }

.swiper-pagination {
    z-index: 12;
  }
  .swiper-pagination-bullet {
    background-color: #f0f1f3;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--primary-c);
  }
`;

const VisualInnerSliderItem = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const SwiperImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const VisualInnerSliderOverlay1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0), black);
  opacity: 0.6;
`;

const VisualInnerSliderOverlay2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

`;

const VisualInnerContentContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 20px;
  z-index: 10;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  width: 100%;

  @media (min-width: 768px) {
    width: 100%;
    padding: 0;
    display: block;
  }
`;

const VisualInnerTitle = styled.h2`
  font-size: calc(8.0rem * 0.38);
  font-weight: 700;
  letter-spacing: -0.015em;
  animation: fadeInDown 1.5s forwards;


  @media (min-width: 768px) {
  font-size: 8.0rem;
  font-weight: 800;
  }
`;


const VisualInnerText = styled.p`
  font-weight: 300;
  line-height: 1.5;
  animation: fadeInDown 1.5s forwards;


  @media (min-width: 768px) {
    font-size: 1.7rem;
  }
`;

const GlobalStyle = createGlobalStyle`
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const PaginationButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 20px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 10;
  gap: 10px;
`;


const PaginationButton = styled.button`
  width: 30px;
  height: 30px;

  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default function MobileNewVisual() {
  const [swiperIndex, setSwiperIndex] = useState(0); // -> 페이지네이션용
  const [swiper, setSwiper] = useState(); // -> 슬라이드용

  const handlePrev = () => {
    swiper?.slidePrev()
  }
  const handleNext = () => {
    swiper?.slideNext()
  }

  const swiperSetting = {
    speed: 1000,
    autoplay: { delay: 3200, disableOnInteraction: false },
    effect: 'fade',
    pagination: {
      clickable: true,
    },
    loop: true,
    modules: [EffectFade, Autoplay, Pagination, Mousewheel],
    className: "mySwiper",
    onActiveIndexChange: (e) => setSwiperIndex(e.realIndex),
    onSwiper: (e) => { setSwiper(e) }
  }
  return (
    <VisualSection>
      <Swiper {...swiperSetting}>
        <SwiperSlide>
          <VisualInnerSliderItem>
            <VisualInnerContentContainer>
              <VisualInnerTitle>
                Trusted <br />Accounting Partner
              </VisualInnerTitle>
              <VisualInnerText>
                신뢰할 수 있는 회계 파트너로서, <br />정확한 재무 관리와 투명한 보고를 제공합니다.
              </VisualInnerText>
            </VisualInnerContentContainer>
            <SwiperImage src={newVisual01} alt="" />
            <VisualInnerSliderOverlay1 />
          </VisualInnerSliderItem>
        </SwiperSlide>
        <SwiperSlide>
          <VisualInnerSliderItem>
            <SwiperImage src={newVisual02} alt="" />
            <VisualInnerContentContainer>
              <VisualInnerTitle>
                Your Financial Ally
              </VisualInnerTitle>
              <VisualInnerText>
                고객의 재정 파트너로서,<br /> 목표 달성을 위해 함께합니다.
              </VisualInnerText>
            </VisualInnerContentContainer>
            <VisualInnerSliderOverlay2 />
          </VisualInnerSliderItem>
        </SwiperSlide>
        <SwiperSlide>
          <VisualInnerSliderItem>
            <SwiperImage src={newVisual03} alt="" />
            <VisualInnerContentContainer>
              <VisualInnerTitle>
                Clear Financial Solutions
              </VisualInnerTitle>
              <VisualInnerText>
                명확하고 신뢰할 수 있는 재무 솔루션을 통해  <br />고객의 성공을 지원합니다.
              </VisualInnerText>
            </VisualInnerContentContainer>
            <VisualInnerSliderOverlay1 />
          </VisualInnerSliderItem>
        </SwiperSlide>
        <SwiperSlide>
          <VisualInnerSliderItem>
            <VisualInnerContentContainer>
              <VisualInnerTitle>
                Precision in Finance
              </VisualInnerTitle>
              <VisualInnerText>
                재무 관리에서의 정확성을 중시하며, <br /> 최상의 회계 솔루션을 제공합니다.
              </VisualInnerText>
            </VisualInnerContentContainer>
            <SwiperImage src={newVisual04} alt="" />
            <VisualInnerSliderOverlay2 />
          </VisualInnerSliderItem>
        </SwiperSlide>
        <PaginationButtonContainer>
          <PaginationButton onClick={handlePrev}>
            <img src={prevIcon} alt="이전" />
          </PaginationButton>
          <PaginationButton onClick={handleNext}>
            <img src={nextIcon} alt="다음" />
          </PaginationButton>
        </PaginationButtonContainer>
      </Swiper>
    </VisualSection>
  )
}