import styled from "styled-components";
import { forwardRef, useMemo, useState } from "react";
import MobileKakaoMap from "./mobile/MobileKakaoMap";

const LocationInfoSection = styled.section`
  width: 100%;
  height: 100%;
  min-height: 1300px;
  font-size: 6rem;
  overflow: hidden;
  position: relative;

  @media (max-width: 1400px) {
    padding:  0 20px;
  }
`;

const LocationInfoContainer = styled.div`
  width: 100%;
  height: 1237px;
  margin: 0 auto;
  max-width: 1400px;
  padding: 5% 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  `

const LocationTitleContainer = styled.div`
  &.animate {
    animation: fadeInLeft 1.5s forwards;
    transition-delay: 0.4s;

     
    @keyframes fadeInLeft {
      from {
        opacity: 0;
        transform: translateX(-100px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
        }
      }
  }
`;

const MapContainer = styled.div`
  &.animate {
    animation: fadeInRight 1.5s forwards;
    transition-delay: 0.4s;

     
    @keyframes fadeInRight {
      from {
        opacity: 0;
        transform: translateX(100px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
        }
      }
  }
`

const SectionTitle = styled.div`
  font-size: 20rem;
  font-weight: 800;
  color: #f9f9f9;
  font-style: italic;
  position: absolute;
  top: -110px;
  line-height: 1;
  height: 200px;
  z-index: -1;


  @media (max-width: 1400px) {
    position: static;
    transform: translateY(0);
  }
`;

const SectionSubTitle = styled.h2`
  font-size: 7.0rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 38px;
`;


const DirectionList = styled.ul`
  border: 1px solid #E4E4E4;
  display: flex;
  height: 52px;
  font-size: 1.6rem;
  margin-bottom: 28px;
  `;

const DirectionListItem = styled.li`
  border-right: 1px solid #d4d4d4;
  flex: 1;

  &:last-child {
    border-right: none;
  }
`;

const DirectionListItemSpan = styled.span`
 height: 100%;
 display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--primary-c);
  color: #fff;

  &:hover{
    color: var(--primary-c);
    background-color: #fff;
    transition: all 0.3s ease;
  } 

  &.active {
    color: var(--primary-c);
    background-color: #fff;
  }
`;

const OfficeInfoContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 28px;
  animation: fadeInUp 1.5s forwards;
  transition-delay: 0.4s;
`

const ActiveOfficeTitle = styled.h3`
 font-size: calc(7.0rem* 0.3);
  font-weight: 700;
  color: var(--primary-c);
  border-bottom: 1px solid var(--primary-c);
  width: 100%;
  line-height: 35px;
  margin-bottom: 20px;
`;

const OfficeInfoBox = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const OfficeInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  font-size: 1.4rem;
  margin-bottom: 30px;
  flex: 1;

  >h4 {
  font-size: 1.8rem;
  font-weight: 700;
  }
`;

const OfficeInfoContactContainer = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 10px;

    >li{
      display: flex;
      align-items: center;
      gap: 5px;
    }
`;

const LocationSection = forwardRef((props, ref) => {
  console.log(ref, "location ref")
  const InfoData = [
    {
      name: "본점",
      address: "서울특별시 영등포구 양산로 107, 인곡빌딩 4층,7층,9층",
      tel: "02-6679-5500, 02-6679-5505",
      email: "ssd402@ssdac.kr, saesidae01@gmail.com"
    },
    {
      name: "구로분실",
      address: "서울특별시 구로구 경인로53길15, 407호(구로동, 업무A동)",
      tel: "0507-1460-5503",
      email: ""
    },
    {
      name: "강남분실",
      address: "서울특별시 강남구 테헤란로38길29, 6층(역삼동, 이원빌딩)",
      tel: "",
      email: ""
    },
    {
      name: "광주지점(분사무소)",
      address: "광주광역시 서구 상무대로759, 4층(치평동, 지산빌딩)",
      tel: "062-373-5573",
      email: ""
    }
  ]

  const locationList = useMemo(() => ["본점", "구로분실", "강남분실", "광주지점"], []);
  const [activeLocation, setActiveLocation] = useState("본점");
  const activeLocationIndex = useMemo(() => locationList.indexOf(activeLocation), [activeLocation, locationList]);

  const handleLocationClick = (location) => {
    setActiveLocation(location);
  }

  return (
    <LocationInfoSection ref={ref}>
      <LocationInfoContainer >
        <LocationTitleContainer className={props.animate ? 'animate' : ''}>
          <SectionTitle>LOCATION</SectionTitle>
          <SectionSubTitle>DIRECTION & LOCATION</SectionSubTitle>
        </LocationTitleContainer>
        <MapContainer className={props.animate ? 'animate' : ''}>
          <DirectionList>
            {locationList.map((location, index) => (
              <DirectionListItem key={index}>
                <DirectionListItemSpan className={activeLocation === location ? "active" : ""} onClick={() => handleLocationClick(location)}>{location}</DirectionListItemSpan>
              </DirectionListItem>
            ))}
          </DirectionList>
          <MobileKakaoMap activeLocation={activeLocation} height={"474px"} />
          <OfficeInfoContainer>
            <ActiveOfficeTitle>{activeLocation}</ActiveOfficeTitle>
            <OfficeInfoBox>
              <OfficeInfoWrapper>
                <h4>ADDRESS</h4>
                <p>{InfoData[activeLocationIndex].address}</p>
              </OfficeInfoWrapper>
              {InfoData[activeLocationIndex].tel &&
                <OfficeInfoWrapper>
                  <h4>CONTACT</h4>
                  <OfficeInfoContactContainer>
                    <li><p>TEL</p>: {InfoData[activeLocationIndex].tel}</li>
                    {InfoData[activeLocationIndex].fax && <li><p>FAX</p>: {InfoData[activeLocationIndex].fax}</li>}
                    {InfoData[activeLocationIndex].email && <li><p>E-MAIL</p>: {InfoData[activeLocationIndex].email}</li>}
                  </OfficeInfoContactContainer>
                </OfficeInfoWrapper>
              }
            </OfficeInfoBox>
          </OfficeInfoContainer>
        </MapContainer>
      </LocationInfoContainer>
    </LocationInfoSection>
  );
});

export default LocationSection;